// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "TechStack-module--TechStacksWebHireAngular--2e5b0";
export var button = "TechStack-module--button--4ad28";
export var cardBg = "TechStack-module--cardBg--e1be4";
export var cards = "TechStack-module--cards--e8a79";
export var cardsContentAngular = "TechStack-module--cardsContentAngular--9e610";
export var description = "TechStack-module--description--cb0a9";
export var headSearch = "TechStack-module--headSearch--c9d06";
export var heading = "TechStack-module--heading--1481b";
export var iconssCard = "TechStack-module--iconssCard--064e9";
export var inputSerch = "TechStack-module--inputSerch--08e37";
export var nav = "TechStack-module--nav--c1bb6";
export var navItem = "TechStack-module--nav-item--3700e";
export var navbarBlock = "TechStack-module--navbarBlock--9bc48";
export var searchBar = "TechStack-module--searchBar--7922d";
export var tabData = "TechStack-module--tabData--bb810";
export var tech = "TechStack-module--tech--cd5a9";
export var techIcon = "TechStack-module--techIcon--007f8";
export var technologyIcon = "TechStack-module--technologyIcon--db557";