// extracted by mini-css-extract-plugin
export var SliderWidth = "TabsMobile-module--SliderWidth--5f541";
export var Trust = "TabsMobile-module--Trust--86992";
export var arrowPoint = "TabsMobile-module--arrowPoint--015e3";
export var btns = "TabsMobile-module--btns--5b520";
export var buton = "TabsMobile-module--buton--fcf9e";
export var cards = "TabsMobile-module--cards--d0c03";
export var dec = "TabsMobile-module--dec--25afb";
export var heading = "TabsMobile-module--heading--f2daa";
export var image2 = "TabsMobile-module--image2--aaea9";
export var indDec = "TabsMobile-module--indDec--e9691";
export var points = "TabsMobile-module--points--508ec";
export var sheildImgTop = "TabsMobile-module--sheildImgTop--3369d";
export var shieldTopBannerImg = "TabsMobile-module--shieldTopBannerImg--d8f15";
export var skill = "TabsMobile-module--skill--83f4e";
export var text = "TabsMobile-module--text--d1133";