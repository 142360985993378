// extracted by mini-css-extract-plugin
export var banner = "Banner-module--banner--4f00f";
export var bannerHeading = "Banner-module--bannerHeading--8e0bf";
export var baseBtn = "Banner-module--baseBtn--d6f48";
export var bnrImg = "Banner-module--bnrImg--caa09";
export var curve = "Banner-module--curve--80bce";
export var customeHead = "Banner-module--customeHead--10d70";
export var description = "Banner-module--description--51abe";
export var heading = "Banner-module--heading--a296e";
export var mainTitle = "Banner-module--mainTitle--6afa5";
export var spanColor = "Banner-module--spanColor--a8de3";