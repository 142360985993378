// extracted by mini-css-extract-plugin
export var card = "IdeaToImpact-module--card--36e15";
export var cardWrapper = "IdeaToImpact-module--cardWrapper--ed468";
export var enterpriseBuildBg = "IdeaToImpact-module--enterpriseBuildBg--665a9";
export var fixed = "IdeaToImpact-module--fixed--aca67";
export var headInline = "IdeaToImpact-module--headInline--86f3c";
export var hire = "IdeaToImpact-module--hire--98d06";
export var hireCyber = "IdeaToImpact-module--hireCyber--87584";
export var imgCol = "IdeaToImpact-module--imgCol--2321c";
export var leftCol = "IdeaToImpact-module--leftCol--9dd50";
export var number = "IdeaToImpact-module--number--1711b";
export var start = "IdeaToImpact-module--start--b0035";
export var subHeading = "IdeaToImpact-module--subHeading--3ad71";