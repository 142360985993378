import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"
import tick from "../../images/svg/tick.svg"


const Banner = ({ strapiData, custome }) => {

  return (
    <div className={`${styles.discoveryBanner} ${custome ? styles.customeBanner : ""}`}>
      <Container>
        <Row>
          <Col xl={6} lg={12} md={12}>
            <div className={styles.heading}>
              <h1
                className={`${styles.mainTitle} ${
                  custome ? styles.customeHead : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={`${styles.bannerHeading} ${
                  custome ? styles.customeBanner : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            {/* <ul
              className={`${styles.list} ${
                custome ? styles.customeBanner : ""
              }`}
            >
              <li
                className={`${styles.listItem} ${
                  custome ? styles.customeBanner : ""
                }`}
              >
                <img src={tick} decoding="async" loading="lazy" alt="icon" />
                <p className={styles.description}>Lowers The Project Risks</p>
              </li>
              <li
                className={`${styles.listItem} ${
                  custome ? styles.customeBanner : ""
                }`}
              >
                <img src={tick} decoding="async" loading="lazy" alt="icon" />
                <p className={styles.description}>Clarify The Project Scope</p>
              </li>
              <li
                className={`${styles.listItem} ${
                  custome ? styles.customeBanner : ""
                }`}
              >
                <img src={tick} decoding="async" loading="lazy" alt="icon" />
                <p className={styles.description}>
                  Smooth Alignment Among Stakeholders
                </p>
              </li>
            </ul> */}
            <div className={`${styles.btns} blueButton`}>
              {strapiData?.buttons[0] && (
                  <Link to={strapiData?.buttons[0]?.url}>
                    <p>{strapiData?.buttons[0]?.title}</p>
                  </Link>
                )}
            </div>
          </Col>
          <Col xl={6} lg={12} md={12}>
            {/* <div className={styles.bnrImg}>
              <img src={bulb} alt="bulb" />
            </div> */}
          </Col>
        </Row>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner
