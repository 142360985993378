import React from "react"
import * as styles from "./Market.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"

function Arrow(props) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div className={styles.iconContainer}>
      <div
        className={styles.portfolioArrowRightIconCover}
        onClick={() => {
          if (currentSlide === slideCount - 1) {
            sliderRef?.slickGoTo(0) // Reset to first slide
          } else {
            onClick() // Move to next slide
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

function PrevArrow(props) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div
        className={styles.portfolioArrowIconCover}
        onClick={() => {
          if (currentSlide === 0) {
            sliderRef?.slickGoTo(slideCount - 1) // Go to last slide if at the first
          } else {
            onClick() // Move to previous slide
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const Seamless = ({ strapiData, projectEngRTest }) => {
  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <h2
            className={`${styles.heading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          ></p>

          <Row className={`gap-30 ${styles.cardWrapper}`}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col lg={3} md={6} xs={12} key={i} className={styles.colGap}>
                  <div className={styles.card}>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: e?.title,
                      }}
                    ></h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                      className={styles.cardDec}
                    />
                    <img
                      className={styles.cardImg}
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default Seamless
