import React from "react"
import { Link } from "gatsby"
import * as styles from "./Trusted.module.scss"
import { Col, Container, Row } from "react-bootstrap"

function Trusted({ strapiData }) {
  return (
    <div className={styles.trusted}>
      <Container>
        <Row>
          <Col lg={6}>
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
           <div className={styles.serBtn}>
           <Link
              to={"/contact-us/"}
              className="newBtn mb-5"
              // style={{ color: "white" }}
            >
              Let’s Make It Happen
            </Link>
           </div>
          </Col>
          <Col lg={6}>
            <div className={styles.trustedCards}>
              {strapiData?.cards?.map((e, i) => (
                <ul className={styles.blueBullets}>
                  <p>{e?.title}</p>
                </ul>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Trusted
