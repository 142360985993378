// extracted by mini-css-extract-plugin
export var SliderWidth = "Partner-Success-module--SliderWidth--e9324";
export var blueText = "Partner-Success-module--blueText--fcebf";
export var clientContent = "Partner-Success-module--clientContent--22cf5";
export var coma = "Partner-Success-module--coma--a60b7";
export var content = "Partner-Success-module--content--5c9e8";
export var customModal = "Partner-Success-module--customModal--56761";
export var dec = "Partner-Success-module--dec--a49e0";
export var designation = "Partner-Success-module--designation--7d94f";
export var dot = "Partner-Success-module--dot--80a75";
export var heading = "Partner-Success-module--heading--977e0";
export var iconContainer = "Partner-Success-module--iconContainer--43259";
export var iconContainerLeft = "Partner-Success-module--iconContainerLeft--15b03";
export var imageContainer = "Partner-Success-module--imageContainer--ee101";
export var logos = "Partner-Success-module--logos--1f9b3";
export var logosBg = "Partner-Success-module--logosBg--bd0b5";
export var name = "Partner-Success-module--name--87597";
export var partnerSuccess = "Partner-Success-module--partnerSuccess--8dabe";
export var playBtn = "Partner-Success-module--playBtn--fd2c7";
export var portfolioArrowIcon = "Partner-Success-module--portfolioArrowIcon--c22eb";
export var portfolioArrowIconCover = "Partner-Success-module--portfolioArrowIconCover--16353";
export var portfolioArrowRightIconCover = "Partner-Success-module--portfolioArrowRightIconCover--190ac";
export var view = "Partner-Success-module--view--e5c5c";