// extracted by mini-css-extract-plugin
export var black = "Post-discovery-module--black--1822e";
export var heading = "Post-discovery-module--heading--17681";
export var icon = "Post-discovery-module--icon--8fd9f";
export var phaseCol = "Post-discovery-module--phaseCol--b6b00";
export var phaseHeader = "Post-discovery-module--phaseHeader--61dd0";
export var phaseIcon = "Post-discovery-module--phaseIcon--c9601";
export var productDiscovery = "Post-discovery-module--productDiscovery--298ab";
export var progress = "Post-discovery-module--progress--1edab";
export var step = "Post-discovery-module--step--ddf3b";
export var stepIcon = "Post-discovery-module--stepIcon--c8102";
export var subDescription = "Post-discovery-module--subDescription--431ee";
export var white = "Post-discovery-module--white--5ce7b";