// extracted by mini-css-extract-plugin
export var SliderWidth = "Success-module--SliderWidth--5ac3d";
export var Trust = "Success-module--Trust--62e4a";
export var blueText = "Success-module--blueText--fd616";
export var clientContent = "Success-module--clientContent--a58e3";
export var coma = "Success-module--coma--b49d3";
export var content = "Success-module--content--0d3b6";
export var customHeading = "Success-module--customHeading--20453";
export var customModal = "Success-module--customModal--5d26a";
export var dec = "Success-module--dec--53873";
export var description = "Success-module--description--87e51";
export var designation = "Success-module--designation--6e8e8";
export var dot = "Success-module--dot--1b361";
export var heading = "Success-module--heading--aec97";
export var iconContainer = "Success-module--iconContainer--43846";
export var iconContainerLeft = "Success-module--iconContainerLeft--31ce3";
export var logos = "Success-module--logos--01edf";
export var logosBg = "Success-module--logosBg--fa99b";
export var name = "Success-module--name--bee13";
export var playBtn = "Success-module--playBtn--5bf04";
export var portfolioArrowIcon = "Success-module--portfolioArrowIcon--4173a";
export var portfolioArrowIconCover = "Success-module--portfolioArrowIconCover--8a24a";
export var portfolioArrowRightIconCover = "Success-module--portfolioArrowRightIconCover--d462a";
export var view = "Success-module--view--c8907";