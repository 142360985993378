// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--eb4dc";
export var bannerHeading = "Banner-module--bannerHeading--25682";
export var btn = "Banner-module--btn--06c5c";
export var curve = "Banner-module--curve--80fea";
export var dedicated = "Banner-module--dedicated--3917d";
export var dedicatedBanner = "Banner-module--dedicatedBanner--c4312";
export var dedicatedHeading = "Banner-module--dedicatedHeading--61215";
export var description = "Banner-module--description--adc2b";
export var innerDedicated = "Banner-module--innerDedicated--1d0d8";
export var newBtn = "Banner-module--newBtn--3f70d";
export var subTitle = "Banner-module--subTitle--90054";