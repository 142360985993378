import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import React, { useEffect } from "react"

const SEORevamp = ({
  description,
  lang,
  meta,
  url = "",
  title,
  image = "https://invozone-backend.s3.amazonaws.com/invozone_og_3df3c5e89a.webp",
  schemas,
  keywords,
  children,
}) => {
  const location = useLocation()
  const metaDescription = description
  const defaultTitle = "InvoZone"
  const metakeywords = keywords || ""
  const PageUrl = url || location?.href

  const metaTitle =
    title?.length + 11 >= 60 ||
    title?.toLocaleLowerCase()?.includes(defaultTitle.toLocaleLowerCase())
      ? title
      : `${title} - ${defaultTitle}`

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "InvoZone",
    alternateName: "InvoZone Pvt. Ltd.",
    url: "https://invozone.com",
    logo: "https://invozone.com/static/ef4c1f0be0caee5a4596e5c2311d5e27/fc97f/InvoZone_Logo.webp",
    email: "sales@invozone.com",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+17867537752",
      contactType: "sales",
      areaServed: "US",
      availableLanguage: "en",
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "130 NW 77th Ave",
      addressLocality: "Pembroke Pines",
      addressRegion: "FL",
      postalCode: "33024",
      addressCountry: "US",
    },
    sameAs: [
      "https://www.facebook.com/InvoZone1/",
      "https://twitter.com/invozone",
      "https://www.instagram.com/invozone",
      "https://www.youtube.com/channel/UCamPgA6MxGvg2T38QY1wOSQ",
      "https://www.linkedin.com/company/invozone",
      "https://invozone.com/",
    ],
  }

  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "InvoZone",
    image:
      "https://invozone.com/static/ef4c1f0be0caee5a4596e5c2311d5e27/fc97f/InvoZone_Logo.webp",
    "@id": "",
    url: "https://invozone.com",
    telephone: "+17867537752",
    address: {
      "@type": "PostalAddress",
      streetAddress: "130 NW 77th Ave",
      addressLocality: "Pembroke Pines",
      addressRegion: "FL",
      postalCode: "33024",
      addressCountry: "US",
    },
    sameAs: [
      "https://www.facebook.com/InvoZone1/",
      "https://twitter.com/invozone",
      "https://www.instagram.com/invozone/",
      "https://www.youtube.com/channel/UCamPgA6MxGvg2T38QY1wOSQ",
      "https://www.linkedin.com/company/invozone/",
      "https://invozone.com/blog",
      "https://invozone.com/technews/",
    ],
    email: "sales@invozone.com",
    geo: {
      "@type": "GeoCoordinates",
      latitude: 26.010881679799095,
      longitude: -80.24151085488819,
    },
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday"],
        opens: "09:00",
        closes: "23:00",
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "Friday",
        opens: "09:00",
        closes: "22:00",
      },
    ],
  }

  const siteLinkSchema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "InvoZone",
    url: "https://invozone.com",
    potentialAction: {
      "@type": "SearchAction",
      target: "{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  }
  useEffect(() => {
    if (location.pathname === "/enterprise-application-development/")
      navigate("/software-development-services/enterprise/")
    if (location.pathname === "/software-project-rescue/")
      navigate("/software-development-services/rescue/")
  }, [location.pathname])

  return (
    <>
      <title>{metaTitle}</title>
      {/* <meta
        name="title"
        content={
          title?.includes(defaultTitle) ? title : `${title} - ${defaultTitle}`
        }
      /> */}
      <meta name="description" content={metaDescription} />
      <meta name="image" content={image} />
      {/* <meta name="author" content="Furqan Aziz" /> */}
      <meta name="robots" content="index, follow" />
      <meta name="robots" content="max-snippet:-1" />
      <meta name="googlebot" content="index, follow" />
      <meta name="googlebot-news" content="index, follow" />
      <meta name="googlebot-news" content="max-snippet:-1" />
      <meta property="og:locale" content="en-us" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={PageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={metaTitle} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:url" content={PageUrl} />
      <meta name="twitter:site" content="@invozone" />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={metaTitle} />
      <meta name="twitter:creator" content="@invozone" />
      <meta name="keywords" content={metakeywords} />
      <meta name="referrer" content="no-referrer-when-downgrade" />
      {children}
      {location?.pathname === "/" && (
        <script defer={true} async={true} type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
      <script defer={true} async={true} type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script>
      <script defer={true} async={true} type="application/ld+json">
        {JSON.stringify(siteLinkSchema)}
      </script>
      {schemas?.map(v => (
        <script defer={true} async={true} type="application/ld+json">
          {v?.structuredData}
        </script>
      ))}
    </>
  )
}

export default SEORevamp
