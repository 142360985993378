import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./TechStack.scss"
import * as styles from "./TechStack.module.scss"

const data = [
  {
    title: "All",
    tech: [],
  },
  {
    title: "Front-End",
    tech: [
      {
        name: "HTML",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_17_ad882713c2.svg",
      },
      {
        name: "CSS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_6_0da85377ce.svg",
      },
      {
        name: "Javascript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_24_64f1e0fa79.svg",
      },
      {
        name: "SaaS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_56_688475039e.svg",
      },
      {
        name: "React JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_50_c93c4841d2.svg",
      },
      {
        name: "Vue JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_63_d9105ea78f.svg",
      },
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_2_df8f915dc8.svg",
      },
      {
        name: "Meteor JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_31_6747d43108.svg",
      },
      {
        name: "Nuxt JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_38_529e7ff082.svg",
      },
      {
        name: "WebGL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_65_55f5a780f2.svg",
      },
    ],
  },
  {
    title: "Back-End",
    tech: [
      {
        name: "Node-js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_ba6f19e132.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_9828a276c4.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elixir_617ae7f1a2.svg",
      },
      {
        name: "Ruby",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_632c73bf20.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_af6208f7f7.svg",
      },
      {
        name: "php",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_1d3b64b85d.svg",
      },
      {
        name: "Golang",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_897a5b704b.svg",
      },
      {
        name: "C#",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/c_e3bc66767f.svg",
      },
      {
        name: "C++",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/C_4cd508eb6d.svg",
      },
      {
        name: "Rust",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_ae892f1b03.svg",
      },
      {
        name: "Nest-js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nest_js_347dd24c27.svg",
      },
      {
        name: ".Net Core",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dot_net_core_5afd52a4f6.svg",
      },
    ],
  },
  {
    title: "Low/No Code",
    tech: [
      {
        name: "Shopify",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_57_a25c1b4ea5.svg",
      },
      {
        name: "Wordpress",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_67_8bd99d404b.svg",
      },
      {
        name: "Strapi",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_59_3f41747aac.svg",
      },
      {
        name: "bubble.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_70_53054090d2.svg",
      },
      {
        name: "builder.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_71_a166f6744a.svg",
      },
      {
        name: "Zoho",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_69_2a490c7292.svg",
      },
      {
        name: "Zapier",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_68_9709da4b29.svg",
      },
      {
        name: "Webflow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_64_f72b309b77.svg",
      },
      {
        name: "Wix",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_66_c9f9f0ba42.svg",
      },
    ],
  },
  {
    title: "Database",
    tech: [
      {
        name: "Firebase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_c6c4691af1.svg",
      },
      {
        name: "Mongodb",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mangodb_0b552dbfc4.svg",
      },
      {
        name: "Postgresql",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_018ca56509.svg",
      },
      {
        name: "Couch-db",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/couch_db_9d0e36ccfe.svg",
      },
      {
        name: "Db",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/db_469cb3200a.svg",
      },
      {
        name: "Sqlite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_9fc822116d.svg",
      },
      {
        name: "Ms-sql",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ms_sql_c9279e2570.svg",
      },
      {
        name: "Aws-dynamodb",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_dynamodb_8ce325843f.svg",
      },
      {
        name: "Oracle",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_6be07ed081.svg",
      },
      {
        name: "Mysql",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_99960cd0a3.svg",
      },
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_8ef174bc41.svg",
      },
    ],
  },
  {
    title: "DevOps",
    tech: [
      {
        name: "Aws",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_1_716b4e2e92.svg",
      },
      {
        name: "Gcp",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_15_65ba1959e0.svg",
      },
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_3_79a3725f15.svg",
      },
      {
        name: "Ibm-cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_19_8781b97904.svg",
      },
      {
        name: "Digital-ocean",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_9_6bfce2475c.svg",
      },
      {
        name: "Oracle-cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_42_7401e31b3a.svg",
      },
      {
        name: "Puppet",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_47_8ffa861d43.svg",
      },
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_28_9cb2e449f5.svg",
      },
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_10_1be6abb5a3.svg",
      },
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_25_d89d554a77.svg",
      },
      {
        name: "Chef",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_7_558453bb6f.svg",
      },
      {
        name: "Terraform",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_62_d9eb76d12c.svg",
      },
    ],
  },
  {
    title: "Mobile",
    tech: [
      {
        name: "Android",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_ce04718bfc.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_27_56cfcc433d.svg",
      },
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_60_e37f871f35.svg",
      },
      {
        name: "Objective-c",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_39_32e642f0b4.svg",
      },
      {
        name: "React-native",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_51_b75d5583be.svg",
      },
      {
        name: "Ionic",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_20_ff0a1d4c97.svg",
      },
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_14_a2da91a4de.svg",
      },
      {
        name: "IoS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_72_ab44e0e6c2.svg",
      },
      {
        name: "Pwa",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_44_03aa04ef5a.svg",
      },
    ],
  },
  {
    title: "AI & ML",
    tech: [
      {
        name: "Tensorflow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_61_5a8a59d7bb.svg",
      },
      {
        name: "Keras",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_26_64ee75390a.svg",
      },
      {
        name: "Pytorch",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_49_73c670d284.svg",
      },
      {
        name: "Lisp",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_9e3e298a1e.svg",
      },
      {
        name: "NTKL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_34_630f07a5ca.svg",
      },
      {
        name: "Spacy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_58_051b5ac148.svg",
      },
      {
        name: "Open-ai",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_40_6029de2f93.svg",
      },
      {
        name: "Ploty",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/image_29_21354b97d2.svg",
      },
      {
        name: "Matplotlib",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_30_0e43e7f16e.svg",
      },
      {
        name: "Pandas",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_45_0ef2cdfd6f.svg",
      },
      {
        name: "Opencv",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_41_5ac74a1383.svg",
      },
      {
        name: "Numpy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_37_5807d2951c.svg",
      },
      {
        name: "Scikit_learn",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_73_0a455ac5e2.svg",
      },
    ],
  },
]

const TechStacks = ({ strapiData }) => {
  data[0].tech = data
    .slice(1)
    .reduce((acc, category) => acc.concat(category.tech), [])

  const [filteredData, setFilteredData] = useState(data)
  const [activeTab, setActiveTab] = useState(data[0]?.title)
  const [searchTerm, setSearchTerm] = useState("")

  const handleTabClick = label => {
    setActiveTab(label)
  }

  const handleSearchChange = event => {
    setSearchTerm(event.target.value)
    const filteredData = data.map(category => ({
      ...category,
      tech: category.tech.filter(tech =>
        tech.name.toLowerCase().includes(event.target.value.toLowerCase())
      ),
    }))
    setFilteredData(filteredData)
    // if (event.key == "Enter") {
    //   handleSearchClick()
    // }
  }

  const handleSearchClick = () => {
    const filteredData = data.map(category => ({
      ...category,
      tech: category.tech.filter(tech =>
        tech.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    }))
    setFilteredData(filteredData)
  }

  return (
    <div className="home">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <div className={styles.headSearch}>
            <h2 className={styles.heading}>
              Build Your Own Team - <span>Hire Developer</span>
            </h2>

            <div className={styles.searchBar}>
              <button className={styles.button} onClick={handleSearchClick}>
                <img
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007174_c4a11766e4.svg"
                  decoding="async"
                  loading="lazy"
                  alt="search-icon"
                />
              </button>
              <input
                type="text"
                placeholder="What are you looking for?"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleSearchChange}
                className={styles.inputSerch}
              />
            </div>
          </div>
          <p className={styles.description}>
            We’ve built our business by assembling a team of skilled developers,
            experts in the frameworks and technologies driving modern solutions.
          </p>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={`${styles.cardsContentAngular}`}>
              <Nav variant="pills" className={`home`}>
                {filteredData &&
                  filteredData?.map((e, i) => (
                    <Nav.Item key={i} className="cards">
                      <div
                        eventKey={`${e?.title}`}
                        className={`tabLink ${
                          activeTab === e.title ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(e.title)}
                      >
                        <div className={styles.tabData}>
                          <div dangerouslySetInnerHTML={{ __html: e?.title }} />
                        </div>
                      </div>
                    </Nav.Item>
                  ))}
              </Nav>

              <Tab.Content className="homeStack">
                {filteredData &&
                  filteredData?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <img
                                  decoding="async"
                                  loading="lazy"
                                  src={el?.icon}
                                  alt={el?.name}
                                  style={{ height: "62px", width: "62px" }}
                                />

                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}

export default TechStacks
