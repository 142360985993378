// extracted by mini-css-extract-plugin
export var SliderWidth = "TabsMobile-module--SliderWidth--a32d3";
export var Trust = "TabsMobile-module--Trust--ad196";
export var arrowPoint = "TabsMobile-module--arrowPoint--acca6";
export var buton = "TabsMobile-module--buton--355f9";
export var cards = "TabsMobile-module--cards--61447";
export var dec = "TabsMobile-module--dec--35998";
export var heading = "TabsMobile-module--heading--68b96";
export var image2 = "TabsMobile-module--image2--e77f1";
export var indDec = "TabsMobile-module--indDec--6dcf5";
export var points = "TabsMobile-module--points--76946";
export var sheildImgTop = "TabsMobile-module--sheildImgTop--20a47";
export var shieldTopBannerImg = "TabsMobile-module--shieldTopBannerImg--328b4";
export var skill = "TabsMobile-module--skill--cbdd0";
export var text = "TabsMobile-module--text--13d02";