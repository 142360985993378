// extracted by mini-css-extract-plugin
export var SliderWidth = "ChallengesOfIT-module--SliderWidth--ef843";
export var card = "ChallengesOfIT-module--card--c148c";
export var cardWrapper = "ChallengesOfIT-module--cardWrapper--83684";
export var challengesOfIT = "ChallengesOfIT-module--challengesOfIT--acf71";
export var description = "ChallengesOfIT-module--description--ba993";
export var fineBg = "ChallengesOfIT-module--fineBg--ec468";
export var firstChild = "ChallengesOfIT-module--firstChild--3c94b";
export var heading = "ChallengesOfIT-module--heading--1dbf4";
export var headingPremium = "ChallengesOfIT-module--headingPremium--a5602";
export var iconContainer = "ChallengesOfIT-module--iconContainer--9c5e9";
export var iconContainerLeft = "ChallengesOfIT-module--iconContainerLeft--dd7bc";
export var imageContainer = "ChallengesOfIT-module--imageContainer--d945d";
export var portfolioArrowIcon = "ChallengesOfIT-module--portfolioArrowIcon--bbef3";
export var portfolioArrowIconCover = "ChallengesOfIT-module--portfolioArrowIconCover--a58a1";
export var portfolioArrowRightIconCover = "ChallengesOfIT-module--portfolioArrowRightIconCover--96716";
export var premiumImg = "ChallengesOfIT-module--premiumImg--21276";
export var teamButton = "ChallengesOfIT-module--teamButton--3f1ff";