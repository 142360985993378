// extracted by mini-css-extract-plugin
export var arrowAnimation = "DedicatedSoftwareTeam-module--arrowAnimation--5e7b8";
export var blueBullets = "DedicatedSoftwareTeam-module--blueBullets--90fd8";
export var boxContent = "DedicatedSoftwareTeam-module--boxContent--55fe4";
export var bulletIcon = "DedicatedSoftwareTeam-module--bulletIcon--3e00d";
export var cards = "DedicatedSoftwareTeam-module--cards--9f0e9";
export var cardsContent = "DedicatedSoftwareTeam-module--cardsContent--847ab";
export var cardsZ = "DedicatedSoftwareTeam-module--cardsZ--9f991";
export var desc = "DedicatedSoftwareTeam-module--desc--80857";
export var featureItem = "DedicatedSoftwareTeam-module--featureItem--95d34";
export var featuresList = "DedicatedSoftwareTeam-module--featuresList--e3044";
export var heading = "DedicatedSoftwareTeam-module--heading--6b01d";
export var newBtn = "DedicatedSoftwareTeam-module--newBtn--7833e";