// extracted by mini-css-extract-plugin
export var card = "IdeaToImpact-module--card--62b58";
export var cardWrapper = "IdeaToImpact-module--cardWrapper--707c6";
export var enterpriseBuildBg = "IdeaToImpact-module--enterpriseBuildBg--13bb3";
export var fixed = "IdeaToImpact-module--fixed--d7fac";
export var headInline = "IdeaToImpact-module--headInline--69e32";
export var hire = "IdeaToImpact-module--hire--eb328";
export var hireCyber = "IdeaToImpact-module--hireCyber--8c143";
export var imgCol = "IdeaToImpact-module--imgCol--cfdbf";
export var leftCol = "IdeaToImpact-module--leftCol--9cd65";
export var number = "IdeaToImpact-module--number--bb7cc";
export var start = "IdeaToImpact-module--start--aada2";
export var subHeading = "IdeaToImpact-module--subHeading--544ac";