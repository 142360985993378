// extracted by mini-css-extract-plugin
export var SliderWidth = "Engagement-Modal-module--SliderWidth--27fef";
export var arrowImg = "Engagement-Modal-module--arrowImg--e5b3e";
export var card = "Engagement-Modal-module--card--02f3f";
export var cardImg = "Engagement-Modal-module--cardImg--2a865";
export var cardWrapper = "Engagement-Modal-module--cardWrapper--cad82";
export var card_description = "Engagement-Modal-module--card_description--26361";
export var card_title = "Engagement-Modal-module--card_title--839b8";
export var cards = "Engagement-Modal-module--cards--de619";
export var cmsWebIconCOntainer = "Engagement-Modal-module--cmsWebIconCOntainer--deff2";
export var cmsWebIconCOntainerLeft = "Engagement-Modal-module--cmsWebIconCOntainerLeft--21641";
export var description = "Engagement-Modal-module--description--8f6e5";
export var engagementModal = "Engagement-Modal-module--engagementModal--4d85a";
export var engagementModalCardMain = "Engagement-Modal-module--engagementModalCardMain--a7a6a";
export var heading = "Engagement-Modal-module--heading--3f335";
export var headingPremium = "Engagement-Modal-module--headingPremium--9ed07";
export var portfolioArrowIcon = "Engagement-Modal-module--portfolioArrowIcon--d4b81";
export var portfolioArrowIconCover = "Engagement-Modal-module--portfolioArrowIconCover--2a5b1";
export var portfolioArrowRightIconCover = "Engagement-Modal-module--portfolioArrowRightIconCover--e08f8";
export var premiumImg = "Engagement-Modal-module--premiumImg--ff0ba";
export var providingImg = "Engagement-Modal-module--providingImg--db875";
export var teamButton = "Engagement-Modal-module--teamButton--0d7f2";
export var trailBg = "Engagement-Modal-module--trailBg--31323";