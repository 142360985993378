// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--08018";
export var bannerHeading = "Banner-module--bannerHeading--efb76";
export var btn = "Banner-module--btn--8df3c";
export var curve = "Banner-module--curve--d0b0f";
export var dedicated = "Banner-module--dedicated--d899a";
export var dedicatedBanner = "Banner-module--dedicatedBanner--7f7fd";
export var dedicatedHeading = "Banner-module--dedicatedHeading--0f919";
export var description = "Banner-module--description--4d886";
export var innerDedicated = "Banner-module--innerDedicated--bcca5";
export var newBtn = "Banner-module--newBtn--d6fab";
export var subTitle = "Banner-module--subTitle--46a61";