import React, { useState } from "react"
import "./CalendlyBanner.scss"
import { Row, Col, Modal, Button, Container } from "react-bootstrap"
import calendly from "../../images/calendly.svg"
import { InlineWidget } from "react-calendly"

const CalendlyBanner = ({ strapiData1, strapiData2 }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <div className="schedule-section">
        <div className="hero_header">
          <Container className="global_container">
            <h2
              className="hero_title"
              dangerouslySetInnerHTML={{ __html: strapiData1?.title }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: strapiData1?.subTitle,
              }}
              className="hero_sub_title"
            />
          </Container>
        </div>
        <Container className="global_container">
          <Row className="top-section align-items-center">
            <Col lg={5} md={12} className="text-section">
              <h2 dangerouslySetInnerHTML={{ __html: strapiData2?.title }} />
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData2?.subTitle,
                }}
              />
            </Col>
            <Col
              lg={7}
              md={12}
              className="calendar-section d-flex justify-content-center"
            >
              <div
                className="calendly-iframe-container"
              >
                <iframe
                  src="https://calendly.com/invozone/discuss-disruptive-ideas"
                  title="Calendly"
                  className="calendly-iframe"
                />
              </div>
            </Col>
            {/* <Col
              lg={7}
              md={12}
              className="calendar-section d-flex justify-content-center"
            >
              <div className="calendly-img">
                <img src={calendly} alt="calendly" />
                <a
                  className="calendly-link"
                  href="https://calendly.com/invozone/discuss-disruptive-ideas"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="primary" className="schedule-button">
                    Schedule a Meeting
                  </Button>
                </a>
              </div>
            </Col> */}
          </Row>
        </Container>
        <img
          className="curve"
          src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
          alt="curve"
        />
      </div>
      {/* <div className="modal-container">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Schedule a Meeting
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src="https://calendly.com/invozone/discuss-disruptive-ideas"
              title="Calendly"
              style={{ width: "100%", height: "500px" }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div> */}
    </>
  )
}

export default CalendlyBanner
