// extracted by mini-css-extract-plugin
export var SliderWidth = "Mainframe-module--SliderWidth--290db";
export var capabilities = "Mainframe-module--capabilities--56fcf";
export var card = "Mainframe-module--card--d06a4";
export var cardWrapper = "Mainframe-module--cardWrapper--787a5";
export var description = "Mainframe-module--description--7629b";
export var fineBg = "Mainframe-module--fineBg--af5da";
export var flexWrap = "Mainframe-module--flexWrap--5687f";
export var heading = "Mainframe-module--heading--72620";
export var headingPremium = "Mainframe-module--headingPremium--4efa7";
export var iconContainer = "Mainframe-module--iconContainer--a338d";
export var iconContainerLeft = "Mainframe-module--iconContainerLeft--318d1";
export var imgWrapper = "Mainframe-module--imgWrapper--c0166";
export var leftWrap = "Mainframe-module--leftWrap--269d1";
export var portfolioArrowIcon = "Mainframe-module--portfolioArrowIcon--a7415";
export var portfolioArrowIconCover = "Mainframe-module--portfolioArrowIconCover--128fc";
export var portfolioArrowRightIconCover = "Mainframe-module--portfolioArrowRightIconCover--6a39d";
export var premiumImg = "Mainframe-module--premiumImg--9e507";
export var rightWrap = "Mainframe-module--rightWrap--1a3f7";
export var teamButton = "Mainframe-module--teamButton--908b4";