import { graphql } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/it-staff/Banner"
import MainLayout from "../layouts/MainLayout"
import OverCountries from "../components/it-staff/OverCountries"
import ProjectHeirarchy from "../components/it-staff/ProjectsHeirarchy"
import ITStaffAugFreelancing from "../components/it-staff/ITStaffAugFreelancing"
import TechStacks from "../components/it-staff/TechStack"
import Process from "../components/it-staff/Process"
import TechstackMobile from "../components/home-sections/TechstackMobile"

const StaffAugmentationCompany = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const projectHeirarchy = data?.strapiPage?.sections[1]
  const trust = data?.strapiPage?.sections[1]
  const freelance = data?.strapiPage?.sections[2]
  const process = data?.strapiPage?.sections[5]
  const faqs = data?.strapiPage?.sections[6]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <OverCountries  strapiData={trust}/>
      <ProjectHeirarchy strapiData={projectHeirarchy}/>
      <ITStaffAugFreelancing strapiData={freelance}/>
      {!isMobile && !isTablet ? <TechStacks /> : <TechstackMobile />}
      <Process strapiData={process}/>
      <Faqs strapiData={faqs} pageName="Staff Augmentation Company" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "staff-augmentation-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default StaffAugmentationCompany

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/staff_augmentation_company_7031a2796c.svg"
    />
  )
}
