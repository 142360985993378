// extracted by mini-css-extract-plugin
export var SliderWidth = "Partner-success-module--SliderWidth--bd9ff";
export var blueText = "Partner-success-module--blueText--fa0a9";
export var clientContent = "Partner-success-module--clientContent--ae051";
export var coma = "Partner-success-module--coma--13380";
export var content = "Partner-success-module--content--2fd89";
export var customModal = "Partner-success-module--customModal--fa0f5";
export var dec = "Partner-success-module--dec--63dcc";
export var designation = "Partner-success-module--designation--15fcb";
export var dot = "Partner-success-module--dot--de527";
export var heading = "Partner-success-module--heading--6a075";
export var iconContainer = "Partner-success-module--iconContainer--8eb8d";
export var iconContainerLeft = "Partner-success-module--iconContainerLeft--e3e53";
export var imageContainer = "Partner-success-module--imageContainer--31451";
export var logos = "Partner-success-module--logos--2ea22";
export var logosBg = "Partner-success-module--logosBg--9dcab";
export var name = "Partner-success-module--name--66c19";
export var partner = "Partner-success-module--partner--9b930";
export var playBtn = "Partner-success-module--playBtn--7d6ed";
export var portfolioArrowIcon = "Partner-success-module--portfolioArrowIcon--d25be";
export var portfolioArrowIconCover = "Partner-success-module--portfolioArrowIconCover--01a74";
export var portfolioArrowRightIconCover = "Partner-success-module--portfolioArrowRightIconCover--1a8dd";
export var view = "Partner-success-module--view--8645c";