// extracted by mini-css-extract-plugin
export var SliderWidth = "Mainframe-module--SliderWidth--42588";
export var capabilities = "Mainframe-module--capabilities--5453f";
export var card = "Mainframe-module--card--87425";
export var cardWrapper = "Mainframe-module--cardWrapper--69f54";
export var description = "Mainframe-module--description--d6539";
export var fineBg = "Mainframe-module--fineBg--279c4";
export var flexWrap = "Mainframe-module--flexWrap--244e8";
export var heading = "Mainframe-module--heading--7749a";
export var headingPremium = "Mainframe-module--headingPremium--d1f8a";
export var iconContainer = "Mainframe-module--iconContainer--c3e1d";
export var iconContainerLeft = "Mainframe-module--iconContainerLeft--cd159";
export var imgWrapper = "Mainframe-module--imgWrapper--67b0f";
export var leftWrap = "Mainframe-module--leftWrap--ec066";
export var portfolioArrowIcon = "Mainframe-module--portfolioArrowIcon--e0084";
export var portfolioArrowIconCover = "Mainframe-module--portfolioArrowIconCover--4a3e0";
export var portfolioArrowRightIconCover = "Mainframe-module--portfolioArrowRightIconCover--c2333";
export var premiumImg = "Mainframe-module--premiumImg--53668";
export var rightWrap = "Mainframe-module--rightWrap--5c5db";
export var teamButton = "Mainframe-module--teamButton--f1368";