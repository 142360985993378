// extracted by mini-css-extract-plugin
export var SliderWidth = "TabsMobile-module--SliderWidth--b8048";
export var Trust = "TabsMobile-module--Trust--94cd6";
export var arrowPoint = "TabsMobile-module--arrowPoint--023a5";
export var buton = "TabsMobile-module--buton--6ac4a";
export var cards = "TabsMobile-module--cards--9be05";
export var dec = "TabsMobile-module--dec--87b96";
export var heading = "TabsMobile-module--heading--b66e6";
export var image2 = "TabsMobile-module--image2--4ea3d";
export var indDec = "TabsMobile-module--indDec--a9b50";
export var points = "TabsMobile-module--points--fa0ad";
export var sheildImgTop = "TabsMobile-module--sheildImgTop--6f8c1";
export var shieldTopBannerImg = "TabsMobile-module--shieldTopBannerImg--cd352";
export var skill = "TabsMobile-module--skill--02aae";
export var text = "TabsMobile-module--text--cfff7";