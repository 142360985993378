// extracted by mini-css-extract-plugin
export var SliderWidth = "TabsMobile-module--SliderWidth--eb288";
export var Trust = "TabsMobile-module--Trust--d3bfd";
export var arrowPoint = "TabsMobile-module--arrowPoint--8d9c5";
export var buton = "TabsMobile-module--buton--f9c85";
export var cards = "TabsMobile-module--cards--d5aae";
export var dec = "TabsMobile-module--dec--9280c";
export var heading = "TabsMobile-module--heading--c5d21";
export var image2 = "TabsMobile-module--image2--c0a8f";
export var indDec = "TabsMobile-module--indDec--b3d00";
export var points = "TabsMobile-module--points--289a9";
export var sheildImgTop = "TabsMobile-module--sheildImgTop--97026";
export var shieldTopBannerImg = "TabsMobile-module--shieldTopBannerImg--e9fb7";
export var skill = "TabsMobile-module--skill--6298b";
export var text = "TabsMobile-module--text--590fa";