// extracted by mini-css-extract-plugin
export var arrowAnimation = "Qickly-module--arrowAnimation--762db";
export var black = "Qickly-module--black--97be8";
export var blue = "Qickly-module--blue--ec090";
export var calendlyPageMargin = "Qickly-module--calendlyPageMargin--e3398";
export var card = "Qickly-module--card--391cd";
export var card1 = "Qickly-module--card1--f4275";
export var card2 = "Qickly-module--card2--a689b";
export var card3 = "Qickly-module--card3--b7c98";
export var colGap = "Qickly-module--colGap--0ddc4";
export var concerns = "Qickly-module--concerns--09aeb";
export var concernsRow = "Qickly-module--concernsRow--08200";
export var description = "Qickly-module--description--d2d74";
export var heading = "Qickly-module--heading--655ad";
export var newBtn = "Qickly-module--newBtn--3bc9e";
export var progress = "Qickly-module--progress--c060d";
export var progressSec = "Qickly-module--progressSec--6332b";
export var subDescription = "Qickly-module--subDescription--1f944";
export var subHeading = "Qickly-module--subHeading--aa59e";
export var talkBtn = "Qickly-module--talkBtn--d03aa";
export var white = "Qickly-module--white--d3e6c";