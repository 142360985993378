// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--cfea9";
export var bnrImg = "Banner-module--bnrImg--8de2b";
export var curve = "Banner-module--curve--7fd39";
export var customeBtns = "Banner-module--customeBtns--ef2bf";
export var customeHead = "Banner-module--customeHead--24344";
export var customerExp = "Banner-module--customerExp--b1906";
export var description = "Banner-module--description--8ebd7";
export var heading = "Banner-module--heading--47ea1";
export var mainTitle = "Banner-module--mainTitle--76c87";
export var spanColor = "Banner-module--spanColor--ea6cc";