// extracted by mini-css-extract-plugin
export var SliderWidth = "Engagement-Modal-module--SliderWidth--7044b";
export var arrowImg = "Engagement-Modal-module--arrowImg--583c5";
export var card = "Engagement-Modal-module--card--ecba5";
export var cardImg = "Engagement-Modal-module--cardImg--48ab0";
export var cardWrapper = "Engagement-Modal-module--cardWrapper--18e99";
export var card_title = "Engagement-Modal-module--card_title--abe0f";
export var cards = "Engagement-Modal-module--cards--f276c";
export var cmsWebIconCOntainer = "Engagement-Modal-module--cmsWebIconCOntainer--4e3b5";
export var cmsWebIconCOntainerLeft = "Engagement-Modal-module--cmsWebIconCOntainerLeft--c15e9";
export var description = "Engagement-Modal-module--description--77e2a";
export var engagementModalCardMain = "Engagement-Modal-module--engagementModalCardMain--7509a";
export var heading = "Engagement-Modal-module--heading--4a4f8";
export var headingPremium = "Engagement-Modal-module--headingPremium--7112e";
export var itengagementModal = "Engagement-Modal-module--itengagementModal--7a585";
export var portfolioArrowIcon = "Engagement-Modal-module--portfolioArrowIcon--b8f3d";
export var portfolioArrowIconCover = "Engagement-Modal-module--portfolioArrowIconCover--2c8e4";
export var portfolioArrowRightIconCover = "Engagement-Modal-module--portfolioArrowRightIconCover--cf01c";
export var premiumImg = "Engagement-Modal-module--premiumImg--64d91";
export var providingImg = "Engagement-Modal-module--providingImg--2b41d";
export var teamButton = "Engagement-Modal-module--teamButton--12524";
export var trailBg = "Engagement-Modal-module--trailBg--16741";