// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--f33d9";
export var banner = "Banner-module--banner--0776e";
export var bannerHeading = "Banner-module--bannerHeading--21f74";
export var baseBtn = "Banner-module--baseBtn--be351";
export var bnrImg = "Banner-module--bnrImg--68734";
export var btnsContainer = "Banner-module--btnsContainer--66fb4";
export var curve = "Banner-module--curve--0963f";
export var customeHead = "Banner-module--customeHead--1b165";
export var darkBtn = "Banner-module--darkBtn--c0d95";
export var description = "Banner-module--description--50089";
export var heading = "Banner-module--heading--23203";
export var mainTitle = "Banner-module--mainTitle--3432b";
export var spanColor = "Banner-module--spanColor--de563";