import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import devepSer from "../../images/mobile-app-developmemt/devepSer.svg"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Horizon.module.scss"
import "./Horizon.scss"

const data = [
  {
    name: "Product Discovery Workshop",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478877_09b7138d6c.png",
    link: "/discovery-workshop/",
    skills: [
      "Define project goals and user needs with stakeholders.",
      "Identify key challenges and features through brainstorming.",
      "Validate ideas and prioritize features based on feedback.",
      "Set clear metrics to track progress and success.",
    ],
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478941_0dc6a9166c.svg",
    name: "MVP Development For Startups",
    link: "/mvp-development-services/",
    skills: [
      "Build an MVP with essential features for a quick launch.",
      "Launch within three months for fast user feedback.",
      "Refine products based on real-world insights.",
      "Prepare a polished MVP for investor presentations.",
    ],
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478942_f9550503be.svg",
    name: "Fractional CTO",
    link: "/hire-cto/",
    skills: [
      "Lead strategy and tech direction on a part-time basis.",
      "Advise on product development and tech stack choices.",
      "Scale teams and optimize technical processes.",
      "Guide fundraising and infrastructure growth.",
    ],
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Design_bb85693602.svg",
    name: "Technology Stack Selection",
    link: "/contact-us/",
    skills: [
      "Choose technologies based on product needs and goals.",
      "Prioritize scalability, security, and maintainability.",
      "Ensure smooth integration with the product architecture.",
      "Select tools for rapid prototyping and iteration.",
    ],
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478943_993e76d8d7.svg",
    name: "Teams As A Service",
    link: "/staff-augmentation-company/",
    skills: [
      "Work in short cycles for fast delivery and feedback.",
      "Integrate quality assurance into each sprint.",
      "Adapt features based on real-time insights.",
      "Improve continuously through iterative testing.",
    ],
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478944_3d9148df21.svg",
    name: "Support And Maintenance",
    link: "/contact-us/",
    skills: [
      "Get ongoing support for smooth operations.",
      "Regularly update and fix bugs.",
      "Monitor and optimize system performance.",
      "Prevent issues with proactive maintenance.",
    ],
  },
]

const Horizon = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.name) // Initialize with the first tab's name

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <>
      <div className="p-85 horizonTabing">
        <Container>
          <h2 className={styles.heading}>
            <span>Startups</span> Or <span>Business</span> Prospects On The
            Horizon
          </h2>

          <Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Planning">
              <Col lg={6}>
                <div>
                  <Nav variant="pills" className={`flex-column navbarBlock`}>
                    {data?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div
                          className={`tabLink ${
                            activeTab === e.name ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.name)}
                        >
                          {e?.name}
                        </div>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={6}>
                <Tab.Content className="horizonBlock">
                  {data?.map(
                    (e, i) =>
                      activeTab === e.name && ( // Only render the active tab's content
                        <div key={i} className={`tab-pane active`}>
                          <div className={styles.boxContent}>
                            <div className={styles.fineBg}>
                              <img
                                decoding="async"
                                loading="lazy"
                                src={e?.img}
                                // src={e?.image1[0]?.localFile?.publicURL}
                                // alt={e?.image1[0]?.alternativeText}
                              />
                            </div>
                            <h3>{e?.name}</h3>
                            <ul className={styles.blueBullets}>
                              {e.skills.map((skill, index) => (
                                <li key={index}>{skill}</li>
                              ))}
                            </ul>
                            {/* <div className={`${styles.newBtn}`}>
                                  <Link to={"/contact-us/"}>
                                    <p>Get Started</p>
                                  </Link>
                                </div> */}
                            <Link to={e?.link}>
                              <div
                                className={`${styles.blueButton} blueButton`}
                              >
                                <p>Get Started</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      )
                  )}
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Horizon
