// extracted by mini-css-extract-plugin
export var bets = "Engagement-module--bets--cfde4";
export var card = "Engagement-module--card--bbd5b";
export var colPad = "Engagement-module--colPad--7b4d8";
export var description = "Engagement-module--description--f1639";
export var devOpsBg = "Engagement-module--devOpsBg--5c950";
export var devOpsCardBg = "Engagement-module--devOpsCardBg--be949";
export var devOpsDeading = "Engagement-module--devOpsDeading--bb910";
export var devOpsDescription = "Engagement-module--devOpsDescription--2b196";
export var devOpsImg = "Engagement-module--devOpsImg--e1332";
export var heading = "Engagement-module--heading--77283";
export var mainDes = "Engagement-module--mainDes--32a01";