import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Engagement.module.scss"

function Bets({ strapiData, devOps }) {
  return (
    <div className={`${styles.bets} ${devOps ? styles.devOpsBg : ""}`}>
      <Container>
        <h1
          className={`${styles.heading} ${devOps ? styles.devOpsDeading : ""}`}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={`${styles.description} ${
            devOps ? styles.devOpsDescription : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className="gap-30">
          {strapiData?.cards?.map((e, i) => (
            <Col lg={4} className={`${styles.colPad} gap-30 d-flex`}>
              <div
                className={`${styles.card} ${
                  devOps ? styles.devOpsCardBg : ""
                }`}
              >
                <img
                  className={`${devOps ? styles.devOpsImg : ""}`}
                  src={e?.image1[0]?.localFile?.publicURL}
                  alt="tick"
                />
                <h3>{e?.title}</h3>

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Bets
