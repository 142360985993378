// extracted by mini-css-extract-plugin
export var SliderWidth = "Clients-module--SliderWidth--e36f7";
export var Trust = "Clients-module--Trust--ce384";
export var arrowPoint = "Clients-module--arrowPoint--e90cf";
export var blurInOut = "Clients-module--blurInOut--bdfd3";
export var buton = "Clients-module--buton--9df26";
export var cardContent = "Clients-module--cardContent--2fbf2";
export var cardFooter = "Clients-module--cardFooter--ce922";
export var cardHover = "Clients-module--cardHover--23edb";
export var cards = "Clients-module--cards--1c749";
export var description = "Clients-module--description--3aa30";
export var greyCard = "Clients-module--greyCard--6545e";
export var heading = "Clients-module--heading--4b9ee";
export var iconContainer = "Clients-module--iconContainer--22e79";
export var iconContainerLeft = "Clients-module--iconContainerLeft--3e31b";
export var image2 = "Clients-module--image2--774dd";
export var indDec = "Clients-module--indDec--47b9a";
export var newBtn = "Clients-module--newBtn--53962";
export var points = "Clients-module--points--26a11";
export var portfolioArrowIcon = "Clients-module--portfolioArrowIcon--5f5e9";
export var portfolioArrowIconCover = "Clients-module--portfolioArrowIconCover--b1765";
export var portfolioArrowRightIconCover = "Clients-module--portfolioArrowRightIconCover--df92a";
export var redCard = "Clients-module--redCard--a6851";
export var sheildImgTop = "Clients-module--sheildImgTop--cfd57";
export var shieldTopBannerImg = "Clients-module--shieldTopBannerImg--b945e";
export var skill = "Clients-module--skill--ac42c";
export var text = "Clients-module--text--fc546";