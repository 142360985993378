import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Product-delivery.module.scss"
// import ProdIcon1 from "../../images/svg/prodIcon1.svg"
// import ProdIcon2 from "../../images/svg/prodIcon2.svg"
// import ProdIcon3 from "../../images/svg/prodIcon3.svg"
// import ProdIcon4 from "../../images/svg/prodIcon4.svg"
// import ProdIcon5 from "../../images/svg/prodIcon5.svg"

// const cardData = [
//   {
//     icon: ProdIcon1,
//     heading: "Identify Problem and Objectives",
//     description: "Understand the core problem and set clear objectives.",
//     textColor: "black",
//   },
//   {
//     icon: ProdIcon2,
//     heading: "User Research and Persona Development",
//     description: "Gather insights about users and create persona mapping.",
//     textColor: "black",
//   },
//   {
//     icon: ProdIcon3,
//     heading: "Brainstorming Solutions",
//     description:
//       "Generate ideas for potential solutions, User Stoties, use cases",
//     textColor: "black",
//   },
//   {
//     icon: ProdIcon4,
//     heading: "Prioritize Ideas",
//     description:
//       "Rank ideas based on feasibility, impact, & alignment with objectives.",
//     textColor: "black",
//   },
//   {
//     icon: ProdIcon5,
//     heading: "Validate Assumptions",
//     description:
//       "Test the validity of the ideas through initial research & feedback.",
//     textColor: "black",
//   },
// ]

const ProductDelivery = ({ strapiData }) => {
  // console.log("🚀 ~ ProductDelivery ~ strapiData:", strapiData)
  return (
    <div className={styles.postDelivery}>
      <Container>
        <div className={styles.progress}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.subDescription}
            dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
          />
          <Row className="gap-30 justify-content-center">
            {strapiData?.cards.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.cardContainer}`}>
                  <div
                    className={`${styles.card} ${styles[`card${index + 1}`]}`}
                  >
                    <img
                      src={item?.image1[0]?.localFile?.publicURL}
                      className={styles.icon}
                      alt="icon"
                    />
                    <h3>{item?.title}</h3>
                    <p>{item?.subTitle}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default ProductDelivery
