// extracted by mini-css-extract-plugin
export var SliderWidth = "success-stories-module--SliderWidth--ee0f0";
export var arrowIcon = "success-stories-module--arrowIcon--b940b";
export var arrowImage = "success-stories-module--arrowImage--1efe4";
export var arrowLink = "success-stories-module--arrowLink--24606";
export var blurrEffect = "success-stories-module--blurrEffect--48b4a";
export var btn = "success-stories-module--btn--d40a6";
export var btn1 = "success-stories-module--btn1--673b5";
export var btn2 = "success-stories-module--btn2--55fea";
export var caseImages = "success-stories-module--caseImages--95678";
export var clientImgs = "success-stories-module--clientImgs--0d880";
export var content = "success-stories-module--content--29dc3";
export var heading = "success-stories-module--heading--0e669";
export var line = "success-stories-module--line--6243a";
export var logoImages = "success-stories-module--logoImages--129d0";
export var logos = "success-stories-module--logos--4b82c";
export var rowGap = "success-stories-module--rowGap--07232";
export var successStories = "success-stories-module--successStories--0cdf7";
export var talkBtn = "success-stories-module--talkBtn--78a82";
export var talkBtn2 = "success-stories-module--talkBtn2--24255";