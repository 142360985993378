import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import BlueCheck from "../../images/check.svg"
import * as styles from "./Invozone-guarante.module.scss"

const InvoZoneGuarante = ({ strapiData }) => {
  return (
    <div className={styles.invoZoneGuarante}>
      <Container>
        <div className="global_container">
          <Row>
            <Col lg={6} md={12} className="d-flex align-items-center">
              <Row>
                <Col>
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.title,
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.subTitle,
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={12}>
              <Row className={styles.features}>
                {strapiData?.cards?.map((feature, index) => (
                  <Col md={6}>
                    <div key={index} className={styles.featureItem}>
                      <span className={styles.checkIcon}>
                        <img
                          width={23}
                          height={23}
                          src={BlueCheck}
                          alt="check"
                        />
                      </span>
                      <div>
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: feature?.title,
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
export default InvoZoneGuarante
