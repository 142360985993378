import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import BlueCheck from "../../images/product-engineering/bluecheck.png"
import * as styles from "./PromiseInvoZone.module.scss"

const PromiseInvoZone = ({ strapiData }) => {

  return (
    <div className={styles.whyChooseInvozone}>
      <Container className="global_container">
        <Row>
          <Col md={6} className="d-flex align-items-center">
            <Row>
              <Col>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.title,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className={styles.features}>
              {strapiData?.cards?.map((feature, index) => (
                <Col md={6}>
                  <div key={index} className={styles.featureItem}>
                    <span className={styles.checkIcon}>
                      <img width={23} height={23} src={BlueCheck} alt="check" />
                    </span>
                    <div>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: feature?.title,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PromiseInvoZone;