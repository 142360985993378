import { graphql } from "gatsby"
import React from "react"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/product-engineering/Banner"
import OverCountries from "../components/product-engineering/OverCountriesProduct"
import MainLayout from "../layouts/MainLayout"
import DedicatedSoftwareTeam from "../components/product-engineering/DedicatedSoftwareTeam"
import IdeaToImpact from "../components/product-engineering/IdeaToImpact"
import EngineeringCompany from "../components/product-engineering/EngineeringCompany"
import PromiseInvoZone from "../components/product-engineering/PromiseInvoZone"
import FlexibleEngagement from "../components/product-engineering/FlexibleEngagement"
import SuccessfulProjects from "../components/product-engineering/SuccessfulProjects"

const ProductEngineering = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const banner = data?.strapiPage?.sections[0]
  const trust = data?.strapiPage?.sections[1]
  const productDedicatedSoftware = data?.strapiPage?.sections[1]
  const ideaToImpact = data?.strapiPage?.sections[2]
  const engineeringCompany = data?.strapiPage?.sections[3]
  const promiseInvo = data?.strapiPage?.sections[4]
  const flexible = data?.strapiPage?.sections[5]
  const successfulProjects = data?.strapiPage?.sections[6]
  const faqs = data?.strapiPage?.sections[7]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={banner} breadCrumb={data?.strapiPage?.buttons} />
      <OverCountries strapiData={trust}/>
      <DedicatedSoftwareTeam strapiData={productDedicatedSoftware}/>
      <IdeaToImpact strapiData={ideaToImpact}/>
      <EngineeringCompany strapiData={engineeringCompany}/>
      <PromiseInvoZone strapiData={promiseInvo}/>
      <FlexibleEngagement strapiData={flexible}/>
      <SuccessfulProjects strapiData={successfulProjects}/>
      <Faqs strapiData={faqs} />
    </MainLayout>
  )
}

export const query = graphql`
  query productEngineering {
    strapiPage(slug: { eq: "project-engineering-services-1" }) {
      sections {
        title
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`
export default ProductEngineering

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/software_product_development_services_b1fd581822.svg"
    />
  )
}
