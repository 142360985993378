import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Header from "../components/common/Header"
import * as styles from "../components/saas-files/404.module.scss"
import "../components/saas-files/404.scss"
import img_404 from "../images/404-page/404.svg"
import about_us from "../images/404-page/about_us.svg"
import blog from "../images/404-page/blog.svg"
import contact_us from "../images/404-page/contact_us.svg"
import hire_developer from "../images/404-page/hire_developers.svg"
import industries from "../images/404-page/industries.svg"
import portfolio_invozone from "../images/404-page/portfolio_invozone.svg"
import services from "../images/404-page/services.svg"
import { isValidHttpUrl, stringTruncate } from "../utils"
export const Head = () => (
  <>
    <title>Page Not Found</title>
    <meta name="robots" content="noindex, follow"></meta>
    <meta name="googlebot" content="noindex, follow"></meta>
  </>
)
const categoriesData = [
  {
    id: 0,
    title: `Software Development`,
  },
  {
    id: 1,
    title: `App Development`,
  },
]

const pages = [
  {
    image: services,
    title: `Services`,
    slug: `software-development-services`,
  },
  {
    image: industries,
    title: `Industries`,
    slug: `industries`,
  },
  {
    image: portfolio_invozone,
    title: `Portfolio`,
    slug: `portfolio`,
  },
  {
    image: hire_developer,
    title: `Hire Developers`,
    slug: `dedicated-development-team`,
  },
  {
    image: blog,
    title: `Blog`,
    slug: `blog`,
  },
  {
    image: about_us,
    title: `About Us`,
    slug: `about-us`,
  },
  {
    image: contact_us,
    title: `Contact Us`,
    slug: `contact-us`,
  },
]

const NotFoundPage = ({ data }) => {

  const [blogVal, setBlogVal] = useState(0)
  const { sdBlogs, plBlogs } = data
  const blogs = [sdBlogs, plBlogs]

  return (
    <React.Fragment>
      <div className="NavPage">
        <Header bgChanged={true} ppcPageHeader={true} />
      </div>
      <div className={`py-5 px-0 ${styles.sectionA}`}>
        <Container>
          <Row>
            <Col lg={3}>
              <div className={styles.page}>
                <div className={styles.pageHeading}>
                  <h3>Popular Pages</h3>
                </div>
                <div>
                  {pages?.map((e, i) => (
                    <div className={styles.pagesOuter} key={i}>
                      {!isValidHttpUrl(e?.slug) ? (
                        <Link to={`/${e?.slug}/`} className={styles.pages}>
                          <img
                            src={e?.image}
                            alt={e?.title}
                            decoding="async"
                            loading="lazy"
                            width={36}
                            height={36}
                          />
                          <h4>{e?.title}</h4>
                        </Link>
                      ) : (
                        <a href={e?.slug} className={styles.pages}>
                          <img
                            src={e?.image}
                            decoding="async"
                            loading="lazy"
                            alt="titlebg"
                            width={36}
                            height={36}
                          />
                          <h4>{e?.title}</h4>
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col lg={6} md={12} className="text-center">
              <img
                src={img_404}
                alt="404_error"
                decoding="async"
                loading="lazy"
              />

              <div className={styles.content}>
                <h1>Page Not Found.</h1>
                <p>
                  Let's get you back on track! Our Home Page has many paths to
                  explore.
                </p>
              </div>
              <Link to="/">
                <button className="btn_black_border">Back to home</button>
              </Link>
            </Col>
            <Col lg={3.5}>
              <div className={styles.tabs}>
                <div className={styles.errorHeading}>
                  <h3>Recommended Reads</h3>
                </div>
                <div className={styles.categories}>
                  <div className={styles.categoriesText}>
                    {categoriesData?.map((e, i) => (
                      <div onClick={() => setBlogVal(e?.id)} key={i}>
                        <h6 className={`${blogVal === i ? "catTitle" : ""}`}>
                          {e?.title}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>

                {blogs[blogVal]?.nodes?.map((e, i) => (
                  <div className={styles.text} key={i}>
                    <div style={{ width: "40%" }}>
                      <GatsbyImage
                        image={getImage(e?.featuredImage?.localFile)}
                        placeholder="blurred"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                    <div className={styles.date} style={{ width: "60%" }}>
                      <Link to={`/blog/${e?.slug}/`}>
                        <h5>{stringTruncate(e?.title, 55)}</h5>
                      </Link>
                      <h6>Updated {e?.publishedOn}</h6>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    plBlogs: allStrapiBlogPost(
      filter: { categories: { elemMatch: { slug: { eq: "web" } } } }
      limit: 5
      sort: { fields: publishedOn, order: DESC }
    ) {
      nodes {
        slug
        title
        publishedOn(formatString: "MMM DD YYYY")
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    sdBlogs: allStrapiBlogPost(
      filter: {
        categories: { elemMatch: { slug: { eq: "software-development" } } }
      }
      limit: 5
      sort: { fields: publishedOn, order: DESC }
    ) {
      nodes {
        slug
        title
        publishedOn(formatString: "MMM DD YYYY")
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
