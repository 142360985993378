// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "TechStack-module--TechStacksWebHireAngular--06d0c";
export var button = "TechStack-module--button--acafa";
export var cardBg = "TechStack-module--cardBg--eac95";
export var cards = "TechStack-module--cards--81fc9";
export var cardsContentAngular = "TechStack-module--cardsContentAngular--0e250";
export var description = "TechStack-module--description--2d709";
export var headSearch = "TechStack-module--headSearch--7b65a";
export var heading = "TechStack-module--heading--60c7f";
export var iconssCard = "TechStack-module--iconssCard--bd7dc";
export var inputSerch = "TechStack-module--inputSerch--de6ea";
export var nav = "TechStack-module--nav--197f4";
export var navItem = "TechStack-module--nav-item--2c4ae";
export var navbarBlock = "TechStack-module--navbarBlock--59fec";
export var searchBar = "TechStack-module--searchBar--457dc";
export var tabData = "TechStack-module--tabData--7d806";
export var tech = "TechStack-module--tech--efc09";
export var techIcon = "TechStack-module--techIcon--20c02";
export var technologyIcon = "TechStack-module--technologyIcon--4c784";