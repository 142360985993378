import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./LaunchProduct.module.scss"

const LaunchProduct = ({strapiData}) => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2 className={styles.heading}  dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <Row className="gap-30">
            {strapiData?.cards.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <p
                    className={`${styles.heading} ${
                      index === 1 ? styles.blue : styles.black
                    }`}
                  >
                    {item?.title}
                  </p>
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor] 
                    } ${
                      index === 1 ? styles.white : styles.black
                    }`}
                  >
                    {item.subTitle}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default LaunchProduct
