// extracted by mini-css-extract-plugin
export var SliderWidth = "Capabilities-module--SliderWidth--983d8";
export var capabilities = "Capabilities-module--capabilities--d0cf9";
export var card = "Capabilities-module--card--c839e";
export var cardWrapper = "Capabilities-module--cardWrapper--2b038";
export var description = "Capabilities-module--description--cbc76";
export var fineBg = "Capabilities-module--fineBg--c89a4";
export var heading = "Capabilities-module--heading--2f0ff";
export var headingPremium = "Capabilities-module--headingPremium--3f713";
export var iconContainer = "Capabilities-module--iconContainer--55372";
export var iconContainerLeft = "Capabilities-module--iconContainerLeft--f9037";
export var portfolioArrowIcon = "Capabilities-module--portfolioArrowIcon--33dba";
export var portfolioArrowIconCover = "Capabilities-module--portfolioArrowIconCover--9e147";
export var portfolioArrowRightIconCover = "Capabilities-module--portfolioArrowRightIconCover--96695";
export var premiumImg = "Capabilities-module--premiumImg--b6b56";
export var teamButton = "Capabilities-module--teamButton--ad3cd";