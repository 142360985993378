import { Link } from "gatsby"
import React from "react"
import {Container,Row,Col} from "react-bootstrap"
import Count from "../../images/fixed-price/count.svg"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.dedicatedBanner}>
      <Container className={styles.innerDedicated}>
        <div className={styles.dedicated}>
          <Container>
          <Row>
            <Col className={`col-xl-6 col-lg-6 col-md-12 ${styles.left}`}>
            <div className={styles.dedicatedHeading}>
              <h1
                className={styles.subTitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
                }}
             />
              <h2
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
            </div>
            <div className={`blueButton`}>
              {strapiData?.buttons[0] && (
                <Link to={strapiData?.buttons[0]?.url}>
                  <p>{strapiData?.buttons[0]?.title}</p>
                </Link>
              )}
            </div>
            </Col>
            <Col className={`col-xl-6 col-lg-6 col-md-12 ${styles.right}`}>
              <img src={Count} alt=""/>
            </Col>
          </Row>
         
          </Container>
        </div>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner