import React, { useEffect, useRef, useState } from "react"
import * as styles from "./Seamless.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Modal from "react-bootstrap/Modal"
import ReactPlayer from "react-player"
import { Link } from "gatsby"
import Slider from "react-slick"

function Arrow(props, webScope) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div
      className={`${styles.iconContainer} ${
        webScope ? styles.webArrowRight : ""
      }`}
    >
      <div
        className={styles.portfolioArrowRightIconCover}
        onClick={() => {
          if (currentSlide === slideCount - 1) {
            sliderRef?.slickGoTo(0) // Reset to first slide
          } else {
            onClick() // Move to next slide
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

function PrevArrow(props, webScope) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div
      className={`${styles.iconContainerLeft} ${
        webScope ? styles.webArrowLeft : ""
      }`}
    >
      <div
        className={styles.portfolioArrowIconCover}
        onClick={() => {
          if (currentSlide === 0) {
            sliderRef?.slickGoTo(slideCount - 1) // Go to last slide if at the first
          } else {
            onClick() // Move to previous slide
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const Seamless = ({ strapiData, devOps, webScope }) => {
  const [showModal, setShowModal] = useState(false)
  const [currentVideo, setCurrentVideo] = useState("")
  const sliderRef = useRef(null) // Updated slider reference
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed to 3 seconds
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: (
      <PrevArrow
        currentSlide={currentSlide}
        slideCount={strapiData?.cards?.length || 0}
        sliderRef={sliderRef.current}
      />
    ),
    nextArrow: (
      <Arrow
        currentSlide={currentSlide}
        slideCount={strapiData?.cards?.length || 0}
        sliderRef={sliderRef.current}
      />
    ),
    infinite: true, // Enable infinite scrolling
    speed: 500, // Animation speed
    afterChange: current => {
      setCurrentSlide(current) // Update the current slide index
    },
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const handleVideoPlay = videoUrl => {
    setCurrentVideo(videoUrl)
    setShowModal(true)
  }

  return (
    <div
      className={`${styles.Trust} ${devOps ? styles.devOpsTrust : ""} ${
        webScope ? styles.webScopeBg : ""
      }`}
    >
      <Container>
        <h2
          className={`${styles.heading} ${devOps ? styles.devOpsHead : ""} ${
            webScope ? styles.webHeading : ""
          }`}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={`${styles.description} ${devOps ? styles.devOpsDec : ""} ${
            webScope ? styles.descriptionMargin : ""
          }`}
          dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
        />

        <Row className={`gap-30 ${styles.cardWrapper}`}>
          <Slider {...settings} className={styles.SliderWidth} ref={sliderRef}>
            {strapiData?.cards &&
              strapiData?.cards.map((e, i) => (
                <Col xs={12} key={i}>
                  <div className={styles.card}>
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                      decoding="async"
                      loading="lazy"
                    />
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                      className={styles.cardDec}
                    />
                  </div>
                </Col>
              ))}
          </Slider>
        </Row>
      </Container>
    </div>
  )
}

export default Seamless
