// extracted by mini-css-extract-plugin
export var SliderWidth = "Engagement-module--SliderWidth--4796c";
export var arrowImg = "Engagement-module--arrowImg--d1f60";
export var card = "Engagement-module--card--dea37";
export var cardImg = "Engagement-module--cardImg--a97aa";
export var cardWrapper = "Engagement-module--cardWrapper--cec9f";
export var card_description = "Engagement-module--card_description--474e2";
export var card_title = "Engagement-module--card_title--e5999";
export var cards = "Engagement-module--cards--7f072";
export var cmsWebIconCOntainer = "Engagement-module--cmsWebIconCOntainer--50730";
export var cmsWebIconCOntainerLeft = "Engagement-module--cmsWebIconCOntainerLeft--cb40c";
export var description = "Engagement-module--description--6d5f1";
export var engagement = "Engagement-module--engagement--445ea";
export var engagementModalCardMain = "Engagement-module--engagementModalCardMain--04d96";
export var heading = "Engagement-module--heading--5931d";
export var headingPremium = "Engagement-module--headingPremium--8eb9f";
export var portfolioArrowIcon = "Engagement-module--portfolioArrowIcon--a1a87";
export var portfolioArrowIconCover = "Engagement-module--portfolioArrowIconCover--56536";
export var portfolioArrowRightIconCover = "Engagement-module--portfolioArrowRightIconCover--7e88b";
export var premiumImg = "Engagement-module--premiumImg--e2fe6";
export var providingImg = "Engagement-module--providingImg--81cec";
export var teamButton = "Engagement-module--teamButton--8125a";
export var trailBg = "Engagement-module--trailBg--ed500";