// extracted by mini-css-extract-plugin
export var bg = "ITStaffAugFreelancing-module--bg--d0b35";
export var cards = "ITStaffAugFreelancing-module--cards--7ada3";
export var concerns = "ITStaffAugFreelancing-module--concerns--3385c";
export var content = "ITStaffAugFreelancing-module--content--51a39";
export var heading = "ITStaffAugFreelancing-module--heading--ddd04";
export var innerResponsible = "ITStaffAugFreelancing-module--innerResponsible--734f1";
export var points = "ITStaffAugFreelancing-module--points--4ff04";
export var responsible = "ITStaffAugFreelancing-module--responsible--3699b";
export var rowCards = "ITStaffAugFreelancing-module--rowCards--28d25";
export var subTitle = "ITStaffAugFreelancing-module--subTitle--09495";
export var talkBtn = "ITStaffAugFreelancing-module--talkBtn--3dafc";
export var tick = "ITStaffAugFreelancing-module--tick--12bff";