import { graphql } from "gatsby"
import React from "react"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/application-engineering/Banner"
import OverCountries from "../components/application-engineering/OverCountriesProduct"
import MainLayout from "../layouts/MainLayout"
import DedicatedSoftwareTeam from "../components/application-engineering/DedicatedSoftwareTeam"
import ModernMainframe from "../components/application-engineering/Mainframe"
import IdeaToImpact from "../components/application-engineering/IdeaToImpact"
import LaunchProduct from "../components/application-engineering/LaunchProduct"
import LegacySystem from "../components/application-engineering/LegacySystem"

const ApplicationEngineering = ({ data }) => {
  console.log("🚀 ~ ApplicationEngineering ~ data:", data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const banner = data?.strapiPage?.sections[0]
  const trust = data?.strapiPage?.sections[1]
  const productDedicatedSoftware = data?.strapiPage?.sections[1]
  const modernMainframe = data?.strapiPage?.sections[2]
  const futureProofing = data?.strapiPage?.sections[3]
  const flexible = data?.strapiPage?.sections[4]
  const legacy = data?.strapiPage?.sections[5]
  const faqs = data?.strapiPage?.sections[6]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <Banner strapiData={banner}/>
      <OverCountries strapiData={trust}/>
      <DedicatedSoftwareTeam strapiData={productDedicatedSoftware}/>
      <ModernMainframe strapiData={modernMainframe}/>
      <IdeaToImpact strapiData={futureProofing}/>
      <LaunchProduct  strapiData={flexible}/>
      <LegacySystem strapiData={legacy}/>
      <Faqs strapiData={faqs} />
    </MainLayout>
  )
}

export const query = graphql`
  query applicationEngineering {
    strapiPage(slug: { eq: "application-re-engineering-services" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`
export default ApplicationEngineering

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/software_product_development_services_b1fd581822.svg"
    />
  )
}
