// extracted by mini-css-extract-plugin
export var arrowAnimation = "FlexibleEngagement-module--arrowAnimation--bad4f";
export var blackButton = "FlexibleEngagement-module--blackButton--93649";
export var blueButton = "FlexibleEngagement-module--blueButton--cec2e";
export var card = "FlexibleEngagement-module--card--e38ad";
export var colGap = "FlexibleEngagement-module--colGap--499c3";
export var description = "FlexibleEngagement-module--description--850a2";
export var heading = "FlexibleEngagement-module--heading--f1073";
export var newBtn = "FlexibleEngagement-module--newBtn--32f2c";
export var newBtn2 = "FlexibleEngagement-module--newBtn2--5e5fd";
export var progress = "FlexibleEngagement-module--progress--76349";
export var progressSec = "FlexibleEngagement-module--progressSec--aa1b6";
export var subHeading = "FlexibleEngagement-module--subHeading--2432f";