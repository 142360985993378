// extracted by mini-css-extract-plugin
export var arrowAnimation = "LegacySystem-module--arrowAnimation--0d9f0";
export var bannerHeading = "LegacySystem-module--bannerHeading--17fc6";
export var btn = "LegacySystem-module--btn--516ca";
export var curve = "LegacySystem-module--curve--30195";
export var dedicated = "LegacySystem-module--dedicated--cbfc4";
export var dedicatedBanner = "LegacySystem-module--dedicatedBanner--3f5d3";
export var dedicatedHeading = "LegacySystem-module--dedicatedHeading--71b9e";
export var description = "LegacySystem-module--description--42d08";
export var innerDedicated = "LegacySystem-module--innerDedicated--87772";
export var newBtn = "LegacySystem-module--newBtn--19ef8";
export var subTitle = "LegacySystem-module--subTitle--12f78";