// extracted by mini-css-extract-plugin
export var SliderWidth = "TabsMobile-module--SliderWidth--a1b96";
export var Trust = "TabsMobile-module--Trust--6a57c";
export var arrowPoint = "TabsMobile-module--arrowPoint--cedd5";
export var buton = "TabsMobile-module--buton--0f3df";
export var cards = "TabsMobile-module--cards--61b55";
export var dec = "TabsMobile-module--dec--6a895";
export var heading = "TabsMobile-module--heading--fa36a";
export var image2 = "TabsMobile-module--image2--00588";
export var indDec = "TabsMobile-module--indDec--8edd5";
export var points = "TabsMobile-module--points--ebe38";
export var sheildImgTop = "TabsMobile-module--sheildImgTop--5f37b";
export var shieldTopBannerImg = "TabsMobile-module--shieldTopBannerImg--ba09c";
export var skill = "TabsMobile-module--skill--e04a9";
export var text = "TabsMobile-module--text--5b6ad";