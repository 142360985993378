import React from "react"
import { Link } from "gatsby"
import {Container, Row, Col} from "react-bootstrap"
import * as styles from "./ITStaffAugFreelancing.module.scss"

const ITStaffAugFreelancing = ({ strapiData }) => {
  console.log("🚀 ~ ITStaffAugFreelancing ~ strapiData:", strapiData)
  return (
    <div className={styles.responsible}>
      <Container className={styles.innerResponsible}>
        <div className={styles.content}>
          
          <h2 className={styles.heading}  dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }} />
          <p
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          ></p>
        </div>
        <div className={styles.rowCards}>
          {strapiData?.cards?.map((v, i) => (
            <div lg={6} key={i} className={styles.cards}>
              <h3>{v?.title}</h3>
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_e94ee3887e.webp"
                alt="background"
                loading="lazy"
                className={styles.bg}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: v?.description?.description,
                }}
              />
            </div>
          ))}
        </div>
        <div className={styles.concerns}>
          <Row className="justify-content-between align-items-center">
            <h2>Got An Idea Or Concerns? Let’s Discuss.</h2>

            <div className={styles.cardFooter}>
            <Link to={"/contact-us/"} className="newBtn" style={{color: "white"}}>
              Explore More
            </Link>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default ITStaffAugFreelancing