// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--63e38";
export var bnrImg = "Banner-module--bnrImg--b238c";
export var curve = "Banner-module--curve--2f724";
export var customeHead = "Banner-module--customeHead--01351";
export var description = "Banner-module--description--40fe0";
export var discoveryBanner = "Banner-module--discoveryBanner--57238";
export var heading = "Banner-module--heading--74362";
export var list = "Banner-module--list--0db51";
export var listItem = "Banner-module--listItem--b937f";
export var mainTitle = "Banner-module--mainTitle--1ab57";
export var spanColor = "Banner-module--spanColor--db30e";