// extracted by mini-css-extract-plugin
export var SliderWidth = "Success-module--SliderWidth--81a48";
export var Trust = "Success-module--Trust--f827d";
export var blueText = "Success-module--blueText--e605c";
export var clientContent = "Success-module--clientContent--8d26f";
export var clientContentRow = "Success-module--clientContentRow--59acb";
export var clientImage = "Success-module--clientImage--7a712";
export var coma = "Success-module--coma--b5578";
export var content = "Success-module--content--58584";
export var customModal = "Success-module--customModal--8d2dc";
export var dec = "Success-module--dec--ac7b2";
export var designation = "Success-module--designation--b4bda";
export var dot = "Success-module--dot--99282";
export var heading = "Success-module--heading--d8776";
export var iconContainer = "Success-module--iconContainer--1b0d5";
export var iconContainerLeft = "Success-module--iconContainerLeft--8e0b1";
export var logos = "Success-module--logos--f1939";
export var logosBg = "Success-module--logosBg--6441e";
export var name = "Success-module--name--4db81";
export var playBtn = "Success-module--playBtn--7e26f";
export var portfolioArrowIcon = "Success-module--portfolioArrowIcon--77911";
export var portfolioArrowIconCover = "Success-module--portfolioArrowIconCover--64087";
export var portfolioArrowRightIconCover = "Success-module--portfolioArrowRightIconCover--ff853";
export var view = "Success-module--view--8db33";