// extracted by mini-css-extract-plugin
export var Blocks = "DedicatedSoftware-module--Blocks--6b6a0";
export var Blocks2 = "DedicatedSoftware-module--Blocks2--8177a";
export var active = "DedicatedSoftware-module--active--2c9be";
export var arrowAnimation = "DedicatedSoftware-module--arrowAnimation--cc278";
export var blueBullets = "DedicatedSoftware-module--blueBullets--5037c";
export var boxContent = "DedicatedSoftware-module--boxContent--e1ec9";
export var cards = "DedicatedSoftware-module--cards--860e5";
export var cardsContent = "DedicatedSoftware-module--cardsContent--85123";
export var cardsZ = "DedicatedSoftware-module--cardsZ--2c199";
export var contentBlocks = "DedicatedSoftware-module--contentBlocks--65171";
export var desc = "DedicatedSoftware-module--desc--e6c72";
export var heading = "DedicatedSoftware-module--heading--cc805";
export var horizontalTabing = "DedicatedSoftware-module--horizontalTabing--21c88";
export var navPills = "DedicatedSoftware-module--nav-pills--bf7f0";
export var navbarBlock = "DedicatedSoftware-module--navbarBlock--25f64";
export var newBtn = "DedicatedSoftware-module--newBtn--f138d";
export var tabLink = "DedicatedSoftware-module--tabLink--fde73";