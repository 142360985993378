import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData, custome }) => {
  return (
    <div className={`${styles.banner} ${custome ? styles.customeBanner : ""}`}>
      <Container>
        <Row>
          <Col xl={6} lg={12} md={12}>
            <div className={styles.heading}>
              <h2
                className={`${styles.mainTitle} ${
                  custome ? styles.customeHead : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />

              <p
                className={`${styles.bannerHeading} ${
                  custome ? styles.customeBanner : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={styles.btnsContainer}>
              <div className={styles.btns}>
                {strapiData?.buttons[0] && (
                  <Link to={strapiData?.buttons[0]?.url}>
                    <div className={`${styles.baseBtn} blueButton`}>
                      <p>{strapiData?.buttons[0]?.title}</p>
                    </div>
                  </Link>
                )}
              </div>
              <div className={styles.btns}>
                {strapiData?.buttons[1] && (
                  <Link to={strapiData?.buttons[0]?.url}>
                    <div
                      className={`${styles.baseBtn} ${styles.darkBtn} blueButton`}
                    >
                      <p>{strapiData?.buttons[1]?.title}</p>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </Col>
          <Col xl={6} lg={12} md={12}></Col>
        </Row>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner
