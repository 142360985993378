// extracted by mini-css-extract-plugin
export var arrowAnimation = "FutureProofing-module--arrowAnimation--7dee1";
export var black = "FutureProofing-module--black--cb896";
export var card = "FutureProofing-module--card--343b1";
export var card1 = "FutureProofing-module--card1--e0125";
export var card2 = "FutureProofing-module--card2--3b495";
export var card3 = "FutureProofing-module--card3--2e248";
export var colGap = "FutureProofing-module--colGap--9b4ef";
export var colorBlue = "FutureProofing-module--colorBlue--f3fa5";
export var concerns = "FutureProofing-module--concerns--54a36";
export var description = "FutureProofing-module--description--5a639";
export var heading = "FutureProofing-module--heading--47659";
export var izEdge = "FutureProofing-module--izEdge--cc8fe";
export var newBtn = "FutureProofing-module--newBtn--50694";
export var progress = "FutureProofing-module--progress--ca91b";
export var subDescription = "FutureProofing-module--subDescription--717d3";
export var subHeading = "FutureProofing-module--subHeading--80839";
export var talkBtn = "FutureProofing-module--talkBtn--bf546";
export var white = "FutureProofing-module--white--907cf";