// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--29cf9";
export var banner = "Banner-module--banner--f50d0";
export var bannerHeading = "Banner-module--bannerHeading--d2722";
export var baseBtn = "Banner-module--baseBtn--a2859";
export var bnrImg = "Banner-module--bnrImg--38862";
export var btnsContainer = "Banner-module--btnsContainer--58a20";
export var curve = "Banner-module--curve--c07dc";
export var customeHead = "Banner-module--customeHead--686f2";
export var darkBtn = "Banner-module--darkBtn--2cb0c";
export var description = "Banner-module--description--3e642";
export var heading = "Banner-module--heading--74978";
export var mainTitle = "Banner-module--mainTitle--37c88";
export var spanColor = "Banner-module--spanColor--b35dc";