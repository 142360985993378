// extracted by mini-css-extract-plugin
export var SliderWidth = "Seamless-module--SliderWidth--59e83";
export var Trust = "Seamless-module--Trust--b5333";
export var card = "Seamless-module--card--46c16";
export var cardDec = "Seamless-module--cardDec--99346";
export var content = "Seamless-module--content--5aa8b";
export var description = "Seamless-module--description--2431f";
export var devOpsDec = "Seamless-module--devOpsDec--fc449";
export var devOpsHead = "Seamless-module--devOpsHead--734eb";
export var devOpsTrust = "Seamless-module--devOpsTrust--056c3";
export var heading = "Seamless-module--heading--1415c";
export var iconContainer = "Seamless-module--iconContainer--9904c";
export var iconContainerLeft = "Seamless-module--iconContainerLeft--99e00";
export var portfolioArrowIcon = "Seamless-module--portfolioArrowIcon--0ff8f";
export var portfolioArrowIconCover = "Seamless-module--portfolioArrowIconCover--c7ac5";
export var portfolioArrowRightIconCover = "Seamless-module--portfolioArrowRightIconCover--64c84";
export var webArrowLeft = "Seamless-module--webArrowLeft--b7629";
export var webArrowRight = "Seamless-module--webArrowRight--18fe4";