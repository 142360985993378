// extracted by mini-css-extract-plugin
export var SliderWidth = "HorizonMobile-module--SliderWidth--33864";
export var Trust = "HorizonMobile-module--Trust--81aa3";
export var arrowPoint = "HorizonMobile-module--arrowPoint--0446a";
export var buton = "HorizonMobile-module--buton--aa79a";
export var cards = "HorizonMobile-module--cards--c44a3";
export var dec = "HorizonMobile-module--dec--acb00";
export var heading = "HorizonMobile-module--heading--8d668";
export var image2 = "HorizonMobile-module--image2--ece2d";
export var indDec = "HorizonMobile-module--indDec--5fd32";
export var points = "HorizonMobile-module--points--2c83a";
export var sheildImgTop = "HorizonMobile-module--sheildImgTop--4b192";
export var shieldTopBannerImg = "HorizonMobile-module--shieldTopBannerImg--c1282";
export var skill = "HorizonMobile-module--skill--98eb3";
export var text = "HorizonMobile-module--text--e0e6f";