import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ProjectHeirarchy.module.scss"

const ProjectHeirarchy = ({ strapiData }) => {
  return (
    <div className={styles.dedicatedTeam}>
      <Container>
        <div>
          <h2
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          ></h2>
          <p className={styles.desc}>
            Decide which path best suits your project's needs and let us help
            you build the team that's perfect for your success. Here’s a quick
            review of what you get either way:
          </p>
          <Row className={`${styles.rowCards} gap-20`}>
            {strapiData?.cards?.map((v, i) => (
              <Col lg={4} key={i} className={`${styles.cards}`}>
                <div>
                  <img
                    src={v?.image1[0]?.localFile?.publicURL}
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                  />
                  <h3 dangerouslySetInnerHTML={{
              __html: v?.title2,
            }} />
                  <p>{v?.subTitle}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default ProjectHeirarchy
