import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Get-started.module.scss"

const GetStarted = ({ strapiData }) => {
  return (
    <Container>
      <div className={styles.getStarted}>
        <div
          className={`${styles.getStartedContent} d-flex justify-content-between align-items-center`}
        >
          <div  className={`${styles.content} d-flex align-items-center`}>
            <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
            <div
              className={styles.getStartedDescription}
              dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
            />
          </div>
          {strapiData?.buttons[0] && (
            <Link to={strapiData?.buttons[0]?.url}>
              <div className={`${styles.getStartedButton} blueButton`}>
                <p>{strapiData?.buttons[0]?.title}</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </Container>
  )
}

export default GetStarted
