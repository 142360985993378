// extracted by mini-css-extract-plugin
export var arrowAnimation = "FlexibleEngagement-module--arrowAnimation--dd476";
export var blackButton = "FlexibleEngagement-module--blackButton--05041";
export var blueButton = "FlexibleEngagement-module--blueButton--206cf";
export var card = "FlexibleEngagement-module--card--f36bf";
export var colGap = "FlexibleEngagement-module--colGap--909cf";
export var description = "FlexibleEngagement-module--description--afe97";
export var heading = "FlexibleEngagement-module--heading--4ba7a";
export var newBtn = "FlexibleEngagement-module--newBtn--68809";
export var newBtn2 = "FlexibleEngagement-module--newBtn2--77fdf";
export var progress = "FlexibleEngagement-module--progress--2caf1";
export var progressSec = "FlexibleEngagement-module--progressSec--43ca8";
export var subHeading = "FlexibleEngagement-module--subHeading--384d9";
export var subTitle = "FlexibleEngagement-module--subTitle--e06c1";