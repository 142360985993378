// extracted by mini-css-extract-plugin
export var arrowAnimation = "Get-started-module--arrowAnimation--bd50a";
export var card1 = "Get-started-module--card1--c22ae";
export var card2 = "Get-started-module--card2--42840";
export var card3 = "Get-started-module--card3--33f81";
export var content = "Get-started-module--content--eac5d";
export var getStarted = "Get-started-module--getStarted--6e41b";
export var getStartedButton = "Get-started-module--getStartedButton--7b5a1";
export var getStartedContent = "Get-started-module--getStartedContent--9d98f";
export var getStartedDescription = "Get-started-module--getStartedDescription--6fb2b";
export var newBtn = "Get-started-module--newBtn--d6071";
export var talkBtn = "Get-started-module--talkBtn--7128a";