// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--3d940";
export var banner = "Banner-module--banner--daeb9";
export var bannerHeading = "Banner-module--bannerHeading--09043";
export var btns = "Banner-module--btns--dd982";
export var curve = "Banner-module--curve--40f0c";
export var customeBanner = "Banner-module--customeBanner--70631";
export var customeBtn = "Banner-module--customeBtn--083d4";
export var customeBtn2 = "Banner-module--customeBtn2--55208";
export var customeHead = "Banner-module--customeHead--bbb84";
export var description = "Banner-module--description--95307";
export var heading = "Banner-module--heading--e0e89";
export var mainTitle = "Banner-module--mainTitle--d6b49";
export var newBtn = "Banner-module--newBtn--7e8fc";
export var newBtn2 = "Banner-module--newBtn2--d23f6";
export var spanColor = "Banner-module--spanColor--ca55f";
export var spanText = "Banner-module--spanText--5dfde";