// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--fa909";
export var bannerHeading = "Banner-module--bannerHeading--22217";
export var btn = "Banner-module--btn--fe1bc";
export var curve = "Banner-module--curve--c451e";
export var dedicated = "Banner-module--dedicated--c26ff";
export var dedicatedBanner = "Banner-module--dedicatedBanner--1b704";
export var dedicatedHeading = "Banner-module--dedicatedHeading--dcf46";
export var description = "Banner-module--description--ea2c8";
export var innerDedicated = "Banner-module--innerDedicated--9b9c7";
export var newBtn = "Banner-module--newBtn--58012";
export var subTitle = "Banner-module--subTitle--f2466";