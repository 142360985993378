import React from "react"
import { Script, graphql } from "gatsby"
import MainLayout from "../layouts/MainLayout"
import ContactSales from "../components/common/ContactSales"
import CalendlyBanner from "../components/calendly-sections/CalendlyBanner"
import Success from "../components/calendly-sections/Success"
import Qickly from "../components/calendly-sections/Qickly"
import Stories from "../components/calendly-sections/Stories"
import SEORevamp from "../components/common/SEO_Revamp"

// export const Head = ({ data }) => {
//   const { metaTitle, metaDescription } = data?.strapiPage?.seo
//   return (
//     <>
//       <Script
//         src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
//         strategy="post-hydrate"
//       />
//       <SEORevamp
//         title={metaTitle}
//         description={metaDescription}
//         image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
//       />
//     </>
//   )
// }
const calendly = ({ data }) => {
  console.log("calendlyData", data)
  const heroHeader = data?.strapiPage?.sections[0]
  const heroSection = data?.strapiPage?.sections[1]
  const getToKnow = data?.strapiPage?.sections[2]
  const success = data?.strapiPage?.sections[3]
  const stories = data?.strapiPage?.sections[5]

  //   const techWeb = data?.strapiPage?.sections[4]
  //   const protect = data?.strapiPage?.sections[5]

  return (
    <MainLayout>
      <CalendlyBanner strapiData1={heroHeader} strapiData2={heroSection} />
      <Qickly strapiData={getToKnow} calendlyPage={true} />
      <Success strapiData={success} />
      <Stories strapiData={stories} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "calendly-page" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default calendly
