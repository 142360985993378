// extracted by mini-css-extract-plugin
export var Trust = "SuccessfulProjects-module--Trust--9e051";
export var arrowIcon = "SuccessfulProjects-module--arrowIcon--fdb5b";
export var arrowImage = "SuccessfulProjects-module--arrowImage--79bc0";
export var arrowLink = "SuccessfulProjects-module--arrowLink--cf3ba";
export var btn = "SuccessfulProjects-module--btn--b46fe";
export var btn2 = "SuccessfulProjects-module--btn2--6155c";
export var caseImages = "SuccessfulProjects-module--caseImages--e99b7";
export var clientImgs = "SuccessfulProjects-module--clientImgs--7d404";
export var content = "SuccessfulProjects-module--content--38533";
export var headSearch = "SuccessfulProjects-module--headSearch--c6471";
export var heading = "SuccessfulProjects-module--heading--82841";
export var logoImages = "SuccessfulProjects-module--logoImages--87fee";
export var logos = "SuccessfulProjects-module--logos--3803b";
export var rowGap = "SuccessfulProjects-module--rowGap--57591";
export var talkBtn = "SuccessfulProjects-module--talkBtn--5a376";