import React from "react"
import * as styles from "./EngineeringCompany.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import BlueCheck from "../../images/product-engineering/bluecheck.png"

const EngineeringCompany = ({ strapiData }) => {
  return (
    <div className={styles.bgEngCompany}>
    <Container>
      <h1
        className={styles.growTitle}
        dangerouslySetInnerHTML={{ __html: strapiData?.title }}
      />
      <Row className={`${styles.rowGap}`}>
        {strapiData?.cards?.map((item, index) => (
          <div className={styles.cardCompany} key={index}>
            <div className={styles.flexWrapper}>
              <div className={styles.checkCircle}>
                <img src={BlueCheck} alt="Blue Check Icon" />
              </div>
              <h2
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: item?.title }}
              />
            </div>
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item?.subTitle }}
            />
          </div>
        ))}
      </Row>
    </Container>
    </div>
  )
}

export default EngineeringCompany
