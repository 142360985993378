import { Script, graphql } from "gatsby"
import React from "react"
// import ContactSales from "../../components/common/ContactSales"
// import Faqs from "../../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/discovery-workshop/Banner"
import ClientLogos from "../components/home-sections/ClientLogos"
import ClientLogosMobile from "../components/home-sections/ClientLogosMobile"
import MainLayout from "../layouts/MainLayout"
import Stories from "../components/enterprise-software/Stories"
import Success from "../components/enterprise-software/Success"
import Faqs from "../components/common/Faqs2"
import ContactSales from "../components/common/ContactSales"
import PostDiscovery from "../components/discovery-workshop/Post-discovery"
import ProductDelivery from "../components/discovery-workshop/Product-delivery"
import Deliverables from "../components/discovery-workshop/Deliverables"
import ProductDevelopment from "../components/discovery-workshop/Product-Development"
export const Head = ({ data }) => {
  console.log("🚀 ~ Head ~ data:", data)
  const { metaTitle } = data?.strapiPage?.seo
  const { metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}
const DiscoveryWorkshop = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const productdelivery = data?.strapiPage?.sections[2]
  const deliverables = data?.strapiPage?.sections[3]
  const postDiscovery = data?.strapiPage?.sections[4]
  const stories = data?.strapiPage?.sections[5]
  const clientCards = data?.testimonials.sections[0]
  const productDevelopment = data?.strapiPage?.sections[7]
  const faqs = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} />
      <div className="client-logos-container">
        {!isMobile && !isTablet ? <ClientLogos /> : <ClientLogosMobile />}
      </div>
      <ProductDelivery strapiData={productdelivery} />
      <Deliverables strapiData={deliverables} />
      <PostDiscovery strapiData={postDiscovery} />
      <Stories strapiData={stories} />
      <Success strapiData={clientCards} />
      <ProductDevelopment strapiData={productDevelopment} />
      <Faqs
        strapiData={faqs}
        faqBacground={true}
        pageName="Discovery workshop"
      />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query DiscoveryWorkshop {
    strapiPage(slug: { eq: "discovery-workshop-invozone" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
          jsonData {
            discovery_phases {
              icon
              title
              steps {
                icon
                text
              }
            }
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default DiscoveryWorkshop
