// import { Link } from "gatsby"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import React from "react"
// import Col from "react-bootstrap/Col"
// import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import bannerImg from "../../images/it-staff/itbanner.svg"
// import * as styles from "./Banner.module.scss"

// const Banner = ({ strapiData, breadCrumb }) => {
//   const image = getImage(strapiData?.secImages[0]?.localFile)

//   return (
//     <div className={styles.banner}>
//       <Container>
//         <nav className={`mb-4 ${styles.itStaffBreadCrumbs}`}>
//           <Link to={`/`} className="text-dark">
//             Home
//           </Link>
//           {" » "}
//           {breadCrumb?.map((val, i) =>
//             val?.url !== "#" ? (
//               <React.Fragment key={i}>
//                 <Link to={`/${val?.url}/`} className="text-dark">
//                   {val?.title}
//                 </Link>
//                 {" » "}
//               </React.Fragment>
//             ) : (
//               <span className="text-dark">{val?.title}</span>
//             )
//           )}
//         </nav>
//         <Row className="align-items-center">
//           <Col lg={7} md={12}>
//             <div className="">
//               <Link className={styles.btn_black_border}>
//                 <h1>IT Staff Augmentation Company</h1>
//               </Link>
//             </div>
//             <div className={styles.heading}>
//               <p
//                 className={styles.mainHeading}
//                 dangerouslySetInnerHTML={{ __html: strapiData?.title }}
//               />
//               <p
//                 dangerouslySetInnerHTML={{
//                   __html: strapiData?.description?.description,
//                 }}
//               />
//               <div>
//                 <ul className="pr-2 pr-md-0">
//                   {strapiData?.cards &&
//                     strapiData?.cards?.map(el => (
//                       <div className={styles.bannerUl} key={el?.title}>
//                         <Col xs={1} className="">
//                           <div className={styles.liBullet} />
//                         </Col>
//                         <Col xs={11} className="px-0">
//                           <li>{el?.title}</li>
//                         </Col>
//                       </div>
//                     ))}
//                 </ul>
//               </div>
//             </div>
//             <div className={styles.bannerBtn}>
//               {strapiData?.buttons[0] && (
//                 <Link
//                   to={strapiData?.buttons[0]?.url}
//                   className="btn_black_border_banner"
//                 >
//                   {strapiData?.buttons[0]?.title}
//                 </Link>
//               )}
//             </div>
//           </Col>
//           <Col
//             lg={5}
//             md={12}
//             className="text-center text-lg-right mt-5 mt-md-0"
//           >
//             {image ? (
//               <GatsbyImage
//                 placeholder="blurred"
//                 decoding="async"
//                 loading="lazy"
//                 image={image}
//                 alt={strapiData?.secImages[0]?.alternativeText}
//               />
//             ) : (
//               <img
//                 src={bannerImg}
//                 alt="IT Staff Augmentation Company"
//                 decoding="async"
//                 loading="lazy"
//                 className={styles.bannerImg}
//               />
//             )}
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   )
// }

// export default Banner

import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.dedicatedBanner}>
      <Container className={styles.innerDedicated}>
        <div className={styles.dedicated}>
          <Container>
            <div className={styles.dedicatedHeading}>
              <h1
                className={styles.subTitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
                }}
             />
              <h2
                className={styles.bannerHeading}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
            </div>
            <div className={`blueButton`}>
              {strapiData?.buttons[0] && (
                <Link to={strapiData?.buttons[0]?.url}>
                  <p>{"Expand Your Squad"}</p>
                </Link>
              )}
            </div>
          </Container>
        </div>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner