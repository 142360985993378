// extracted by mini-css-extract-plugin
export var arrowAnimation = "DedicatedSoftwareTeam-module--arrowAnimation--009e2";
export var blueBullets = "DedicatedSoftwareTeam-module--blueBullets--d7c18";
export var boxContent = "DedicatedSoftwareTeam-module--boxContent--37ea5";
export var bulletIcon = "DedicatedSoftwareTeam-module--bulletIcon--21635";
export var cards = "DedicatedSoftwareTeam-module--cards--4682f";
export var cardsContent = "DedicatedSoftwareTeam-module--cardsContent--a0e86";
export var cardsZ = "DedicatedSoftwareTeam-module--cardsZ--bfe4e";
export var desc = "DedicatedSoftwareTeam-module--desc--f0565";
export var featureItem = "DedicatedSoftwareTeam-module--featureItem--64011";
export var featuresList = "DedicatedSoftwareTeam-module--featuresList--485f5";
export var heading = "DedicatedSoftwareTeam-module--heading--5c09e";
export var newBtn = "DedicatedSoftwareTeam-module--newBtn--ee02e";