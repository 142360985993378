import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Process.module.scss"

function Process({ strapiData }) {
  return (
    <div className={styles.process}>
      <Container>
        <h2 className={styles.subTitle} dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}/>
        <video
          className={styles.processGif}
          autoPlay
          muted
          poster="https://invozone-backend.s3.us-east-1.amazonaws.com/dedicated_process_12530247e2.png"
        >
          <source
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/ezgif_2_461fd02a64_1_903bf1bcda.webm"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Container>
    </div>
  )
}

export default Process