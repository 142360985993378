import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./DedicatedSoftwareTeam.module.scss"
import "./ProductHorizon.scss"

const DedicatedSoftwareTeam = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(strapiData?.cards[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <>
      <div className="p-85 productHorizontalTabing">
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />

          <Row className="align-items-center">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={strapiData?.cards[0]?.title}
            >
              <Col lg={6}>
                <div>
                  <Nav variant="pills" className={`flex-column navbarBlock`}>
                    {strapiData?.cards?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          {e?.title}
                        </div>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={6}>
                <Tab.Content className="horizonBlock mt-4">
                  {strapiData?.cards?.map(
                    (e, i) =>
                      activeTab === e.title && (
                        <div key={i} className={`tab-pane active`}>
                          <div className={styles.boxContent}>
                            <div className={styles.fineBg}>
                              <img
                                decoding="async"
                                loading="lazy"
                                src={e?.image1[0]?.localFile?.publicURL}
                              />
                            </div>
                            <h3>{e?.title}</h3>
                            <p
                              className={styles.desc}
                              dangerouslySetInnerHTML={{
                                __html: e?.subTitle,
                              }}
                            />
                            <div className={styles.featuresList}>
                              {e?.features?.map((feature, idx) => (
                                <div key={idx} className={styles.featureItem}>
                                  <span className={styles.bulletIcon}>➤</span>
                                  <p>hellow</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default DedicatedSoftwareTeam
