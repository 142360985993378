import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Post-discovery.module.scss"
// import Planning from "../../images/svg/planning.svg"
// import Development from "../../images/svg/development.svg"
// import Validation from "../../images/svg/validation.svg"
// import Launch from "../../images/svg/launch.svg"
// import Icon1 from "../../images/svg/icon1.svg"
// import Icon2 from "../../images/svg/icon2.svg"
// import Icon3 from "../../images/svg/icon3.svg"
// import Icon4 from "../../images/svg/icon4.svg"
// import Icon5 from "../../images/svg/icon5.svg"
// import Icon6 from "../../images/svg/icon6.svg"
// import Icon7 from "../../images/svg/icon7.svg"
// import Icon8 from "../../images/svg/icon8.svg"
// import Icon9 from "../../images/svg/icon9.svg"
// import Icon10 from "../../images/svg/icon10.svg"
// import Icon11 from "../../images/svg/icon11.svg"
// import Icon12 from "../../images/svg/icon12.svg"
// import Icon13 from "../../images/svg/icon13.svg"
// import Icon14 from "../../images/svg/icon14.svg"

// const phases = [
//   {
//     title: "Planning & Design",
//     icon: Planning,
//     steps: [
//       { icon: Icon1, text: "Define Detailed Requirements" },
//       { icon: Icon2, text: "User Stories" },
//       { icon: Icon3, text: "Technical Architecture" },
//       { icon: Icon4, text: "Prototypes" },
//     ],
//   },
//   {
//     title: "Development & Execution",
//     icon: Development,
//     steps: [
//       { icon: Icon5, text: "Implement The Features" },
//       { icon: Icon6, text: "Conduct Testing" },
//       { icon: Icon7, text: "Prepare For Deployment" },
//     ],
//   },
//   {
//     title: "Validation & Refinement",
//     icon: Validation,
//     steps: [
//       { icon: Icon8, text: "Collect User Feedback" },
//       { icon: Icon9, text: "Analyze Data" },
//       { icon: Icon10, text: "Refine The Product" },
//     ],
//   },
//   {
//     title: "Launch & Post-Launch Support",
//     icon: Launch,
//     steps: [
//       { icon: Icon11, text: "Release The Product" },
//       { icon: Icon12, text: "Monitor Performance" },
//       { icon: Icon13, text: "Provide Support" },
//       { icon: Icon14, text: "Plan For Updates" },
//     ],
//   },
// ]

const PostDiscovery = ({ strapiData }) => {
  console.log("🚀 ~ PostDiscovery ~ strapiData:", strapiData)
  console.log("Jsondata", strapiData?.cards[0]?.jsonData?.discovery_phases)
  return (
    <div className={styles.productDiscovery}>
      <Container>
        <div className={styles.progress}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.subDescription}
            dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
          />
          <Row>
            {strapiData?.cards[0]?.jsonData?.discovery_phases?.map(
              (phase, index) => (
                <Col key={index} className={styles.phaseCol}>
                  <div className={styles.phaseHeader}>
                    <div>
                      <img
                        src={phase.icon}
                        alt=""
                        // className={styles.phaseIcon}
                      />
                    </div>
                    <h3>{phase.title}</h3>
                  </div>
                  <div className={styles.stepsContainer}>
                    {phase?.steps?.map((step, idx) => (
                      <div key={idx} className={styles.step}>
                        <div className={styles.stepIcon}>
                          <img src={step.icon} alt="" />
                        </div>
                        <p>{step.text}</p>
                      </div>
                    ))}
                  </div>
                </Col>
              )
            )}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default PostDiscovery
