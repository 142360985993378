// extracted by mini-css-extract-plugin
export var Trust = "SuccessfulProjects-module--Trust--024c6";
export var arrowIcon = "SuccessfulProjects-module--arrowIcon--32a17";
export var arrowImage = "SuccessfulProjects-module--arrowImage--20025";
export var arrowLink = "SuccessfulProjects-module--arrowLink--3b4f2";
export var btn = "SuccessfulProjects-module--btn--ac196";
export var btn2 = "SuccessfulProjects-module--btn2--e6bd5";
export var caseImages = "SuccessfulProjects-module--caseImages--a530a";
export var clientImgs = "SuccessfulProjects-module--clientImgs--53a7b";
export var content = "SuccessfulProjects-module--content--2b427";
export var headSearch = "SuccessfulProjects-module--headSearch--a29df";
export var heading = "SuccessfulProjects-module--heading--c26d5";
export var logoImages = "SuccessfulProjects-module--logoImages--d5b3c";
export var logos = "SuccessfulProjects-module--logos--044ef";
export var rowGap = "SuccessfulProjects-module--rowGap--c2683";
export var talkBtn = "SuccessfulProjects-module--talkBtn--a9915";