// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--37331";
export var bannerHeading = "Banner-module--bannerHeading--ea1ba";
export var btn = "Banner-module--btn--d342e";
export var curve = "Banner-module--curve--852b2";
export var dedicated = "Banner-module--dedicated--9e83b";
export var dedicatedBanner = "Banner-module--dedicatedBanner--e8b59";
export var dedicatedHeading = "Banner-module--dedicatedHeading--e043c";
export var description = "Banner-module--description--1604b";
export var innerDedicated = "Banner-module--innerDedicated--b6133";
export var newBtn = "Banner-module--newBtn--2b41c";
export var subTitle = "Banner-module--subTitle--2e6cf";