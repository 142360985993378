import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Tabs.module.scss"
import "../custom-software/Horizon.scss"
import { Link } from "gatsby"

const data = [
  {
    name: "Web App Development",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/fi_6492030_1_b363ed7cf8.svg",
    dec: "Get a scalable, secure web app designed to meet your unique business needs and drive performance.",
    skills: [
      "Fast, secure performance",
      "Scalable for future growth",
      "Seamless cross-device experience",
      "Customizable features",
    ],
  },
  {
    name: "Mobile App Development",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007751_1_519a5e8c49.svg",
    dec: "Experience smooth and intuitive mobile apps for iOS and Android with seamless functionality across devices.",
    skills: [
      "iOS & Android apps",
      "Scalable for growth",
      "Cross-platform compatibility",
      "Engaging user interface",
    ],
  },
  {
    name: "API Development",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007752_1_441c1a8bed.svg",
    dec: "Enhance your software’s capabilities with flexible, well-documented APIs that ensure easy integration.",
    skills: [
      "Easy system integration",
      "Scalable & flexible APIs",
      "Secure and reliable",
      "Customizable for needs",
    ],
  },
  {
    name: "Enterprise Data Solutions",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007749_1_5d9cfd744f.svg",
    dec: "Streamline and optimize data across systems to drive smarter decisions and enhance performance.",
    skills: [
      "Integrated data systems",
      "Real-time data insights",
      "Improved operational efficiency",
      "Smarter decision-making",
    ],
  },
  {
    name: "Cloud Services",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007756_1_cabe927ecd.svg",
    dec: "Scale your infrastructure with secure, reliable cloud solutions tailored to your business needs.",
    skills: [
      "Flexible cloud deployment",
      "High-performance services",
      "Easy migration & management",
      "Enhanced security & compliance",
    ],
  },
  {
    name: "System Integrations",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007753_1_fd1caa0e14.svg",
    dec: "Connect your systems seamlessly to improve workflows, automation, and overall efficiency.",
    skills: [
      "Seamless system connections",
      "Scalable integration design",
      "Improved workflow automation",
      "Cost-saving efficiency",
    ],
  },
  {
    name: "Real-Time Application Development",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007758_1_02cb1e6ad9.svg",
    dec: "Get real-time data processing and immediate responses to enhance user experience and operational efficiency.",
    skills: [
      "Instant data processing",
      "Real-time insights",
      "Immediate response actions",
      "Enhanced user experience",
    ],
  },
  {
    name: "Launch Ready",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007823_bc407e8c4d.svg",
    dec: "Prepare for a seamless software launch with full infrastructure, hosting, and ongoing support.",
    skills: [
      "Hassle-free go-live",
      "Full hosting & licensing",
      "Pre-launch testing",
      "Post-launch support",
    ],
  },
]

const Tabs = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(strapiData?.cards[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <>
      <div className="p-85 suiteTabing" style={{ backgroundColor: "#f7f7f7" }}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className="align-items-center">
            <Tab.Container id="left-tabs-example" defaultActiveKey="Planning">
              <Col lg={6}>
                <div>
                  <Nav variant="pills" className={`flex-column navbarBlock`}>
                    {strapiData?.cards?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          {e?.title}
                        </div>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={6}>
                <Tab.Content className="horizonBlock">
                  {strapiData?.cards?.map(
                    (e, i) =>
                      activeTab === e.title && ( // Only render the active tab's content
                        <div key={i} className={`tab-pane active`}>
                          <div className={styles.boxContent}>
                            <div className={styles.fineBg}>
                              <img
                                decoding="async"
                                loading="lazy"
                                src={e?.image1[0]?.localFile?.publicURL}
                                // src={e?.image1[0]?.localFile?.publicURL}
                                alt={e?.image1[0]?.alternativeText}
                              />
                            </div>
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                            <p
                              className={styles.desc}
                              dangerouslySetInnerHTML={{
                                __html: e?.subTitle,
                              }}
                            />
                            <div className={styles.btns}>
                              {e?.buttons[0] && (
                                <Link to={e?.buttons[0]?.url}>
                                  <div
                                    className={`${styles.baseBtn} ${styles.darkBtn} blueButton`}
                                  >
                                    <p className="blueButtonTxt">
                                      {e?.buttons[0]?.title}
                                    </p>
                                  </div>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default Tabs
