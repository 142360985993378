// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--60f2c";
export var bannerHeading = "Banner-module--bannerHeading--5f746";
export var btn = "Banner-module--btn--263c4";
export var curve = "Banner-module--curve--c8570";
export var dedicated = "Banner-module--dedicated--37ea9";
export var dedicatedBanner = "Banner-module--dedicatedBanner--8f112";
export var dedicatedHeading = "Banner-module--dedicatedHeading--2fa52";
export var description = "Banner-module--description--2013a";
export var innerDedicated = "Banner-module--innerDedicated--d05d3";
export var newBtn = "Banner-module--newBtn--ffbfb";
export var subTitle = "Banner-module--subTitle--a1344";