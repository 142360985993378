// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--8265a";
export var bannerHeading = "Banner-module--bannerHeading--96fb2";
export var btn = "Banner-module--btn--335fc";
export var curve = "Banner-module--curve--83fbe";
export var dedicated = "Banner-module--dedicated--3c95a";
export var dedicatedBanner = "Banner-module--dedicatedBanner--bcdbc";
export var dedicatedHeading = "Banner-module--dedicatedHeading--807c1";
export var description = "Banner-module--description--77d40";
export var innerDedicated = "Banner-module--innerDedicated--b5ead";
export var newBtn = "Banner-module--newBtn--0f896";
export var subTitle = "Banner-module--subTitle--6f210";