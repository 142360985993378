// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyDevops-module--SliderWidth--b65d9";
export var card = "WhyDevops-module--card--94282";
export var cardWrapper = "WhyDevops-module--cardWrapper--8a493";
export var description = "WhyDevops-module--description--6e7bf";
export var fineBg = "WhyDevops-module--fineBg--16aa4";
export var firstChild = "WhyDevops-module--firstChild--fcca6";
export var heading = "WhyDevops-module--heading--eff0d";
export var headingPremium = "WhyDevops-module--headingPremium--bb576";
export var iconContainer = "WhyDevops-module--iconContainer--5096c";
export var iconContainerLeft = "WhyDevops-module--iconContainerLeft--c54ed";
export var imageContainer = "WhyDevops-module--imageContainer--c2969";
export var portfolioArrowIcon = "WhyDevops-module--portfolioArrowIcon--27d89";
export var portfolioArrowIconCover = "WhyDevops-module--portfolioArrowIconCover--7939c";
export var portfolioArrowRightIconCover = "WhyDevops-module--portfolioArrowRightIconCover--29f7a";
export var premiumImg = "WhyDevops-module--premiumImg--01834";
export var teamButton = "WhyDevops-module--teamButton--f9ed5";
export var whyDevops = "WhyDevops-module--whyDevops--511be";