// extracted by mini-css-extract-plugin
export var arrowAnimation = "IZ-Edge-module--arrowAnimation--308d9";
export var black = "IZ-Edge-module--black--4786f";
export var card = "IZ-Edge-module--card--00ab1";
export var card1 = "IZ-Edge-module--card1--45bcc";
export var card2 = "IZ-Edge-module--card2--db6aa";
export var card3 = "IZ-Edge-module--card3--a1a62";
export var colGap = "IZ-Edge-module--colGap--10943";
export var colorBlue = "IZ-Edge-module--colorBlue--b427f";
export var concerns = "IZ-Edge-module--concerns--b634e";
export var description = "IZ-Edge-module--description--a590a";
export var heading = "IZ-Edge-module--heading--d3f05";
export var izEdge = "IZ-Edge-module--izEdge--ed6bf";
export var newBtn = "IZ-Edge-module--newBtn--754b9";
export var progress = "IZ-Edge-module--progress--22e59";
export var subDescription = "IZ-Edge-module--subDescription--07d5d";
export var subHeading = "IZ-Edge-module--subHeading--302ae";
export var talkBtn = "IZ-Edge-module--talkBtn--55089";
export var white = "IZ-Edge-module--white--c1a61";