// extracted by mini-css-extract-plugin
export var arrowAnimation = "IZ-Contribute-module--arrowAnimation--f4dcd";
export var black = "IZ-Contribute-module--black--7829a";
export var card = "IZ-Contribute-module--card--8a0f3";
export var card1 = "IZ-Contribute-module--card1--aae28";
export var card2 = "IZ-Contribute-module--card2--5d26e";
export var card3 = "IZ-Contribute-module--card3--9c238";
export var colGap = "IZ-Contribute-module--colGap--dd672";
export var colorBlue = "IZ-Contribute-module--colorBlue--92985";
export var concerns = "IZ-Contribute-module--concerns--52d96";
export var description = "IZ-Contribute-module--description--96406";
export var heading = "IZ-Contribute-module--heading--0e4a5";
export var izEdge = "IZ-Contribute-module--izEdge--c0175";
export var newBtn = "IZ-Contribute-module--newBtn--80c19";
export var progress = "IZ-Contribute-module--progress--98653";
export var subDescription = "IZ-Contribute-module--subDescription--06d5f";
export var subHeading = "IZ-Contribute-module--subHeading--f39c3";
export var talkBtn = "IZ-Contribute-module--talkBtn--794bb";
export var white = "IZ-Contribute-module--white--f8c60";