exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ai-development-services-js": () => import("./../../../src/pages/ai-development-services.js" /* webpackChunkName: "component---src-pages-ai-development-services-js" */),
  "component---src-pages-application-development-services-js": () => import("./../../../src/pages/application-development-services.js" /* webpackChunkName: "component---src-pages-application-development-services-js" */),
  "component---src-pages-application-modernization-js": () => import("./../../../src/pages/application-modernization.js" /* webpackChunkName: "component---src-pages-application-modernization-js" */),
  "component---src-pages-application-re-engineering-js": () => import("./../../../src/pages/application-re-engineering.js" /* webpackChunkName: "component---src-pages-application-re-engineering-js" */),
  "component---src-pages-blockchain-development-services-js": () => import("./../../../src/pages/blockchain-development-services.js" /* webpackChunkName: "component---src-pages-blockchain-development-services-js" */),
  "component---src-pages-blogpreview-js": () => import("./../../../src/pages/blogpreview.js" /* webpackChunkName: "component---src-pages-blogpreview-js" */),
  "component---src-pages-calendly-js": () => import("./../../../src/pages/calendly.js" /* webpackChunkName: "component---src-pages-calendly-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cloud-consulting-services-js": () => import("./../../../src/pages/cloud-consulting-services.js" /* webpackChunkName: "component---src-pages-cloud-consulting-services-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-cx-optimization-services-js": () => import("./../../../src/pages/cx-optimization-services.js" /* webpackChunkName: "component---src-pages-cx-optimization-services-js" */),
  "component---src-pages-cyber-security-services-js": () => import("./../../../src/pages/cyber-security-services.js" /* webpackChunkName: "component---src-pages-cyber-security-services-js" */),
  "component---src-pages-dedicated-development-team-js": () => import("./../../../src/pages/dedicated-development-team.js" /* webpackChunkName: "component---src-pages-dedicated-development-team-js" */),
  "component---src-pages-devops-containerization-and-orchestration-services-js": () => import("./../../../src/pages/devops/containerization-and-orchestration-services.js" /* webpackChunkName: "component---src-pages-devops-containerization-and-orchestration-services-js" */),
  "component---src-pages-devops-it-infrastructure-js": () => import("./../../../src/pages/devops/it-infrastructure.js" /* webpackChunkName: "component---src-pages-devops-it-infrastructure-js" */),
  "component---src-pages-devops-js": () => import("./../../../src/pages/devops.js" /* webpackChunkName: "component---src-pages-devops-js" */),
  "component---src-pages-devops-monitoring-and-logging-js": () => import("./../../../src/pages/devops/monitoring-and-logging.js" /* webpackChunkName: "component---src-pages-devops-monitoring-and-logging-js" */),
  "component---src-pages-digital-commerce-services-js": () => import("./../../../src/pages/digital-commerce-services.js" /* webpackChunkName: "component---src-pages-digital-commerce-services-js" */),
  "component---src-pages-discovery-workshop-js": () => import("./../../../src/pages/discovery-workshop.js" /* webpackChunkName: "component---src-pages-discovery-workshop-js" */),
  "component---src-pages-e-commerce-js": () => import("./../../../src/pages/e-commerce.js" /* webpackChunkName: "component---src-pages-e-commerce-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-fintech-js": () => import("./../../../src/pages/fintech.js" /* webpackChunkName: "component---src-pages-fintech-js" */),
  "component---src-pages-fintech-software-development-company-saudi-arabia-ar-js": () => import("./../../../src/pages/fintech-software-development-company-saudi-arabia/ar.js" /* webpackChunkName: "component---src-pages-fintech-software-development-company-saudi-arabia-ar-js" */),
  "component---src-pages-fintech-software-development-company-saudi-arabia-index-js": () => import("./../../../src/pages/fintech-software-development-company-saudi-arabia/index.js" /* webpackChunkName: "component---src-pages-fintech-software-development-company-saudi-arabia-index-js" */),
  "component---src-pages-fixed-price-js": () => import("./../../../src/pages/fixed-price.js" /* webpackChunkName: "component---src-pages-fixed-price-js" */),
  "component---src-pages-food-groceries-js": () => import("./../../../src/pages/food-groceries.js" /* webpackChunkName: "component---src-pages-food-groceries-js" */),
  "component---src-pages-healthcare-js": () => import("./../../../src/pages/healthcare.js" /* webpackChunkName: "component---src-pages-healthcare-js" */),
  "component---src-pages-hire-android-developers-js": () => import("./../../../src/pages/hire-android-developers.js" /* webpackChunkName: "component---src-pages-hire-android-developers-js" */),
  "component---src-pages-hire-angular-developers-js": () => import("./../../../src/pages/hire-angular-developers.js" /* webpackChunkName: "component---src-pages-hire-angular-developers-js" */),
  "component---src-pages-hire-backend-developers-js": () => import("./../../../src/pages/hire-backend-developers.js" /* webpackChunkName: "component---src-pages-hire-backend-developers-js" */),
  "component---src-pages-hire-cto-js": () => import("./../../../src/pages/hire-cto.js" /* webpackChunkName: "component---src-pages-hire-cto-js" */),
  "component---src-pages-hire-data-engineers-js": () => import("./../../../src/pages/hire-data-engineers.js" /* webpackChunkName: "component---src-pages-hire-data-engineers-js" */),
  "component---src-pages-hire-developers-contact-us-js": () => import("./../../../src/pages/hire-developers/contact-us.js" /* webpackChunkName: "component---src-pages-hire-developers-contact-us-js" */),
  "component---src-pages-hire-django-developers-js": () => import("./../../../src/pages/hire-django-developers.js" /* webpackChunkName: "component---src-pages-hire-django-developers-js" */),
  "component---src-pages-hire-drupal-developers-js": () => import("./../../../src/pages/hire-drupal-developers.js" /* webpackChunkName: "component---src-pages-hire-drupal-developers-js" */),
  "component---src-pages-hire-elixir-developers-js": () => import("./../../../src/pages/hire-elixir-developers.js" /* webpackChunkName: "component---src-pages-hire-elixir-developers-js" */),
  "component---src-pages-hire-engineers-contact-us-js": () => import("./../../../src/pages/hire-engineers/contact-us.js" /* webpackChunkName: "component---src-pages-hire-engineers-contact-us-js" */),
  "component---src-pages-hire-flutter-developers-js": () => import("./../../../src/pages/hire-flutter-developers.js" /* webpackChunkName: "component---src-pages-hire-flutter-developers-js" */),
  "component---src-pages-hire-frontend-developers-js": () => import("./../../../src/pages/hire-frontend-developers.js" /* webpackChunkName: "component---src-pages-hire-frontend-developers-js" */),
  "component---src-pages-hire-full-stack-developers-js": () => import("./../../../src/pages/hire-full-stack-developers.js" /* webpackChunkName: "component---src-pages-hire-full-stack-developers-js" */),
  "component---src-pages-hire-golang-developers-js": () => import("./../../../src/pages/hire-golang-developers.js" /* webpackChunkName: "component---src-pages-hire-golang-developers-js" */),
  "component---src-pages-hire-ios-developers-js": () => import("./../../../src/pages/hire-ios-developers.js" /* webpackChunkName: "component---src-pages-hire-ios-developers-js" */),
  "component---src-pages-hire-laravel-developers-js": () => import("./../../../src/pages/hire-laravel-developers.js" /* webpackChunkName: "component---src-pages-hire-laravel-developers-js" */),
  "component---src-pages-hire-mern-stack-developers-js": () => import("./../../../src/pages/hire-mern-stack-developers.js" /* webpackChunkName: "component---src-pages-hire-mern-stack-developers-js" */),
  "component---src-pages-hire-mobile-app-developers-js": () => import("./../../../src/pages/hire-mobile-app-developers.js" /* webpackChunkName: "component---src-pages-hire-mobile-app-developers-js" */),
  "component---src-pages-hire-node-js-developer-js": () => import("./../../../src/pages/hire-node-js-developer.js" /* webpackChunkName: "component---src-pages-hire-node-js-developer-js" */),
  "component---src-pages-hire-penetration-testing-consultants-js": () => import("./../../../src/pages/hire-penetration-testing-consultants.js" /* webpackChunkName: "component---src-pages-hire-penetration-testing-consultants-js" */),
  "component---src-pages-hire-product-manager-js": () => import("./../../../src/pages/hire-product-manager.js" /* webpackChunkName: "component---src-pages-hire-product-manager-js" */),
  "component---src-pages-hire-python-developers-js": () => import("./../../../src/pages/hire-python-developers.js" /* webpackChunkName: "component---src-pages-hire-python-developers-js" */),
  "component---src-pages-hire-react-js-developers-js": () => import("./../../../src/pages/hire-react-js-developers.js" /* webpackChunkName: "component---src-pages-hire-react-js-developers-js" */),
  "component---src-pages-hire-remote-developers-js": () => import("./../../../src/pages/hire-remote-developers.js" /* webpackChunkName: "component---src-pages-hire-remote-developers-js" */),
  "component---src-pages-hire-ruby-on-rails-developer-js": () => import("./../../../src/pages/hire-ruby-on-rails-developer.js" /* webpackChunkName: "component---src-pages-hire-ruby-on-rails-developer-js" */),
  "component---src-pages-hire-saas-developers-js": () => import("./../../../src/pages/hire-saas-developers.js" /* webpackChunkName: "component---src-pages-hire-saas-developers-js" */),
  "component---src-pages-hire-typescript-developers-js": () => import("./../../../src/pages/hire-typescript-developers.js" /* webpackChunkName: "component---src-pages-hire-typescript-developers-js" */),
  "component---src-pages-hire-unreal-engine-developers-js": () => import("./../../../src/pages/hire-unreal-engine-developers.js" /* webpackChunkName: "component---src-pages-hire-unreal-engine-developers-js" */),
  "component---src-pages-hire-vue-js-developers-js": () => import("./../../../src/pages/hire-vue-js-developers.js" /* webpackChunkName: "component---src-pages-hire-vue-js-developers-js" */),
  "component---src-pages-hire-web-developers-js": () => import("./../../../src/pages/hire-web-developers.js" /* webpackChunkName: "component---src-pages-hire-web-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-integrated-management-system-policy-js": () => import("./../../../src/pages/integrated-management-system-policy.js" /* webpackChunkName: "component---src-pages-integrated-management-system-policy-js" */),
  "component---src-pages-javascript-development-company-js": () => import("./../../../src/pages/javascript-development-company.js" /* webpackChunkName: "component---src-pages-javascript-development-company-js" */),
  "component---src-pages-meet-invozone-at-mwc-barcelona-js": () => import("./../../../src/pages/meet-invozone-at-mwc-barcelona.js" /* webpackChunkName: "component---src-pages-meet-invozone-at-mwc-barcelona-js" */),
  "component---src-pages-mobile-app-development-android-js": () => import("./../../../src/pages/mobile-app-development/android.js" /* webpackChunkName: "component---src-pages-mobile-app-development-android-js" */),
  "component---src-pages-mobile-app-development-cross-platform-js": () => import("./../../../src/pages/mobile-app-development/cross-platform.js" /* webpackChunkName: "component---src-pages-mobile-app-development-cross-platform-js" */),
  "component---src-pages-mobile-app-development-hybrid-js": () => import("./../../../src/pages/mobile-app-development/hybrid.js" /* webpackChunkName: "component---src-pages-mobile-app-development-hybrid-js" */),
  "component---src-pages-mobile-app-development-ios-js": () => import("./../../../src/pages/mobile-app-development/ios.js" /* webpackChunkName: "component---src-pages-mobile-app-development-ios-js" */),
  "component---src-pages-mobile-app-development-js": () => import("./../../../src/pages/mobile-app-development.js" /* webpackChunkName: "component---src-pages-mobile-app-development-js" */),
  "component---src-pages-mobile-app-development-react-native-js": () => import("./../../../src/pages/mobile-app-development/react-native.js" /* webpackChunkName: "component---src-pages-mobile-app-development-react-native-js" */),
  "component---src-pages-mvp-development-services-js": () => import("./../../../src/pages/mvp-development-services.js" /* webpackChunkName: "component---src-pages-mvp-development-services-js" */),
  "component---src-pages-no-code-development-services-js": () => import("./../../../src/pages/no-code-development-services.js" /* webpackChunkName: "component---src-pages-no-code-development-services-js" */),
  "component---src-pages-on-demand-services-js": () => import("./../../../src/pages/on-demand-services.js" /* webpackChunkName: "component---src-pages-on-demand-services-js" */),
  "component---src-pages-portfolio-appwork-js": () => import("./../../../src/pages/portfolio/appwork.js" /* webpackChunkName: "component---src-pages-portfolio-appwork-js" */),
  "component---src-pages-portfolio-big-shooter-golf-js": () => import("./../../../src/pages/portfolio/big-shooter-golf.js" /* webpackChunkName: "component---src-pages-portfolio-big-shooter-golf-js" */),
  "component---src-pages-portfolio-column-js": () => import("./../../../src/pages/portfolio/column.js" /* webpackChunkName: "component---src-pages-portfolio-column-js" */),
  "component---src-pages-portfolio-freshprep-js": () => import("./../../../src/pages/portfolio/freshprep.js" /* webpackChunkName: "component---src-pages-portfolio-freshprep-js" */),
  "component---src-pages-portfolio-globalreader-js": () => import("./../../../src/pages/portfolio/globalreader.js" /* webpackChunkName: "component---src-pages-portfolio-globalreader-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-nymcard-js": () => import("./../../../src/pages/portfolio/nymcard.js" /* webpackChunkName: "component---src-pages-portfolio-nymcard-js" */),
  "component---src-pages-portfolio-theraforge-js": () => import("./../../../src/pages/portfolio/theraforge.js" /* webpackChunkName: "component---src-pages-portfolio-theraforge-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-engineering-js": () => import("./../../../src/pages/product-engineering.js" /* webpackChunkName: "component---src-pages-product-engineering-js" */),
  "component---src-pages-referral-program-js": () => import("./../../../src/pages/referral-program.js" /* webpackChunkName: "component---src-pages-referral-program-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-software-development-services-enterprise-js": () => import("./../../../src/pages/software-development-services/enterprise.js" /* webpackChunkName: "component---src-pages-software-development-services-enterprise-js" */),
  "component---src-pages-software-development-services-js": () => import("./../../../src/pages/software-development-services.js" /* webpackChunkName: "component---src-pages-software-development-services-js" */),
  "component---src-pages-software-development-services-rescue-js": () => import("./../../../src/pages/software-development-services/rescue.js" /* webpackChunkName: "component---src-pages-software-development-services-rescue-js" */),
  "component---src-pages-software-development-services-startup-js": () => import("./../../../src/pages/software-development-services/startup.js" /* webpackChunkName: "component---src-pages-software-development-services-startup-js" */),
  "component---src-pages-software-product-development-services-js": () => import("./../../../src/pages/software-product-development-services.js" /* webpackChunkName: "component---src-pages-software-product-development-services-js" */),
  "component---src-pages-software-project-rescue-js": () => import("./../../../src/pages/software-project-rescue.js" /* webpackChunkName: "component---src-pages-software-project-rescue-js" */),
  "component---src-pages-software-quality-assurance-js": () => import("./../../../src/pages/software-quality-assurance.js" /* webpackChunkName: "component---src-pages-software-quality-assurance-js" */),
  "component---src-pages-staff-augmentation-company-js": () => import("./../../../src/pages/staff-augmentation-company.js" /* webpackChunkName: "component---src-pages-staff-augmentation-company-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-travel-tourism-js": () => import("./../../../src/pages/travel-tourism.js" /* webpackChunkName: "component---src-pages-travel-tourism-js" */),
  "component---src-pages-ui-ux-design-js": () => import("./../../../src/pages/ui-ux-design.js" /* webpackChunkName: "component---src-pages-ui-ux-design-js" */),
  "component---src-pages-web-application-development-services-custom-js": () => import("./../../../src/pages/web-application-development-services/custom.js" /* webpackChunkName: "component---src-pages-web-application-development-services-custom-js" */),
  "component---src-pages-web-application-development-services-index-js": () => import("./../../../src/pages/web-application-development-services/index.js" /* webpackChunkName: "component---src-pages-web-application-development-services-index-js" */),
  "component---src-pages-web-application-development-services-usa-js": () => import("./../../../src/pages/web-application-development-services-usa.js" /* webpackChunkName: "component---src-pages-web-application-development-services-usa-js" */),
  "component---src-pages-web-development-services-cms-js": () => import("./../../../src/pages/web-development-services/cms.js" /* webpackChunkName: "component---src-pages-web-development-services-cms-js" */),
  "component---src-pages-web-development-services-consulting-js": () => import("./../../../src/pages/web-development-services/consulting.js" /* webpackChunkName: "component---src-pages-web-development-services-consulting-js" */),
  "component---src-pages-web-development-services-js": () => import("./../../../src/pages/web-development-services.js" /* webpackChunkName: "component---src-pages-web-development-services-js" */),
  "component---src-pages-web-development-services-outsource-js": () => import("./../../../src/pages/web-development-services/outsource.js" /* webpackChunkName: "component---src-pages-web-development-services-outsource-js" */),
  "component---src-templates-author-blogs-js": () => import("./../../../src/templates/author-blogs.js" /* webpackChunkName: "component---src-templates-author-blogs-js" */),
  "component---src-templates-author-profile-js": () => import("./../../../src/templates/author-profile.js" /* webpackChunkName: "component---src-templates-author-profile-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-pages-js": () => import("./../../../src/templates/blogPages.js" /* webpackChunkName: "component---src-templates-blog-pages-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-e-book-js": () => import("./../../../src/templates/e-book.js" /* webpackChunkName: "component---src-templates-e-book-js" */),
  "component---src-templates-e-books-js": () => import("./../../../src/templates/e-books.js" /* webpackChunkName: "component---src-templates-e-books-js" */),
  "component---src-templates-event-details-js": () => import("./../../../src/templates/event-details.js" /* webpackChunkName: "component---src-templates-event-details-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/newsPost.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-podcast-post-js": () => import("./../../../src/templates/podcastPost.js" /* webpackChunkName: "component---src-templates-podcast-post-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

