// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--c4635";
export var bnrImg = "Banner-module--bnrImg--4759f";
export var btns = "Banner-module--btns--fc96a";
export var curve = "Banner-module--curve--ec5d5";
export var customeHead = "Banner-module--customeHead--337ae";
export var description = "Banner-module--description--55ff0";
export var digitalCommerce = "Banner-module--digitalCommerce--6af4a";
export var heading = "Banner-module--heading--e4054";
export var mainTitle = "Banner-module--mainTitle--e014c";
export var spanColor = "Banner-module--spanColor--e32a6";