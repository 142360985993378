// extracted by mini-css-extract-plugin
export var arrowAnimation = "IZValue-module--arrowAnimation--02d45";
export var black = "IZValue-module--black--8b69f";
export var card = "IZValue-module--card--fee02";
export var card1 = "IZValue-module--card1--5637c";
export var card2 = "IZValue-module--card2--ff1b3";
export var card3 = "IZValue-module--card3--7b5bb";
export var colGap = "IZValue-module--colGap--f9462";
export var concerns = "IZValue-module--concerns--928b1";
export var description = "IZValue-module--description--c8408";
export var heading = "IZValue-module--heading--7b3a5";
export var izValue = "IZValue-module--izValue--a2fab";
export var newBtn = "IZValue-module--newBtn--6cf2f";
export var progress = "IZValue-module--progress--2eecc";
export var subDescription = "IZValue-module--subDescription--f8494";
export var subHeading = "IZValue-module--subHeading--f5c31";
export var talkBtn = "IZValue-module--talkBtn--336bc";
export var white = "IZValue-module--white--7cf0d";