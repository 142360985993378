// extracted by mini-css-extract-plugin
export var Trust = "Stories-module--Trust--874b0";
export var arrowIcon = "Stories-module--arrowIcon--8b054";
export var arrowImage = "Stories-module--arrowImage--efbce";
export var arrowLink = "Stories-module--arrowLink--d9744";
export var btn = "Stories-module--btn--9a97a";
export var btn2 = "Stories-module--btn2--5409c";
export var caseImages = "Stories-module--caseImages--da72f";
export var clientImgs = "Stories-module--clientImgs--d8620";
export var content = "Stories-module--content--48a1f";
export var customStories = "Stories-module--customStories--8096a";
export var description = "Stories-module--description--e72db";
export var heading = "Stories-module--heading--607a9";
export var logoImages = "Stories-module--logoImages--ebf0b";
export var logos = "Stories-module--logos--dffb4";
export var rowGap = "Stories-module--rowGap--ccadb";
export var talkBtn = "Stories-module--talkBtn--75459";