// extracted by mini-css-extract-plugin
export var Trust = "Stories-module--Trust--5535e";
export var arrowIcon = "Stories-module--arrowIcon--792f8";
export var arrowImage = "Stories-module--arrowImage--9c3d1";
export var arrowLink = "Stories-module--arrowLink--dc4ff";
export var bannerHeading = "Stories-module--bannerHeading--3e56b";
export var btn = "Stories-module--btn--6be40";
export var btn2 = "Stories-module--btn2--4107c";
export var caseImages = "Stories-module--caseImages--56a5e";
export var clientImgs = "Stories-module--clientImgs--c99d6";
export var content = "Stories-module--content--8c8da";
export var headSearch = "Stories-module--headSearch--be889";
export var heading = "Stories-module--heading--9e5e5";
export var logoImages = "Stories-module--logoImages--99d43";
export var logos = "Stories-module--logos--ba722";
export var rowGap = "Stories-module--rowGap--6795a";
export var talkBtn = "Stories-module--talkBtn--9646c";