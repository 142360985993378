// extracted by mini-css-extract-plugin
export var card = "Product-development-module--card--5775f";
export var cardWrapper = "Product-development-module--cardWrapper--617da";
export var enterpriseBuildBg = "Product-development-module--enterpriseBuildBg--f4ffd";
export var fixed = "Product-development-module--fixed--46434";
export var headInline = "Product-development-module--headInline--ace68";
export var hire = "Product-development-module--hire--a9560";
export var hireCyber = "Product-development-module--hireCyber--b7fa3";
export var imgCol = "Product-development-module--imgCol--ba1de";
export var leftCol = "Product-development-module--leftCol--2d097";
export var number = "Product-development-module--number--6b524";
export var start = "Product-development-module--start--9172f";
export var subHeading = "Product-development-module--subHeading--526b4";