import { Script, graphql } from "gatsby"
import React from "react"
import SEORevamp from "../../components/common/SEO_Revamp"
import Banner from "../../components/it-infrastructure/Banner"
import ClientLogos from "../../components/home-sections/ClientLogos"
import ClientLogosMobile from "../../components/home-sections/ClientLogosMobile"
import DedicatedSoftware from "../../components/it-infrastructure/DedicatedSoftware"
import ChallengesOfIT from "../../components/it-infrastructure/ChallengesOfIT"
import IZHealth from "../../components/it-infrastructure/ITHealth"
import FourStep from "../../components/it-infrastructure/Four-Step"
import EngagementModal from "../../components/it-infrastructure/Engagement-Modal"
import MainLayout from "../../layouts/MainLayout"
import ContactSales from "../../components/common/ContactSales"
import IZContribute from "../../components/it-infrastructure/IZ-Contribute"
import SuccessStories from "../../components/containerization-sections/success-stories"
import PartnerSuccess from "../../components/containerization-sections/Partner-Success"
import Faqs from "../../components/common/Faqs2"
import Tabs from "../../components/it-infrastructure/Tabs"
import TabsMobile from "../../components/it-infrastructure/TabsMobile"

export const Head = ({ data }) => {
  // const { metaTitle, metaDescription } = data?.strapiPage?.seo

  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const ITInfrastructureConsultingServices = ({ data }) => {
  console.log("it-infrastructure-consulting-services", data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const globalIndustires = [
    data?.strapiPage?.sections[3],
    data?.strapiPage?.sections[4],
  ]

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const dedicatedSoftware = data?.strapiPage?.sections[1]
  const challengesOfIT = data?.strapiPage?.sections[2]
  const izContribute = data?.strapiPage?.sections[3]
  const itHeallth = data?.strapiPage?.sections[4]
  const fourStep = data?.strapiPage?.sections[5]
  const engagementModal = data?.strapiPage?.sections[6]
  const successStories = data?.strapiPage?.sections[7]
  const partnerSuccess = data?.strapiPage?.sections[8]
  const faqs = data?.strapiPage?.sections[9]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  const technical = data?.strapiPage?.sections[3]
  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} />
      <div className="client-logos-container">
        {!isMobile && !isTablet ? <ClientLogos /> : <ClientLogosMobile />}
      </div>
      {!isMobile && !isTablet ? (
        <Tabs strapiData={dedicatedSoftware} />
      ) : (
        <TabsMobile strapiData={dedicatedSoftware} />
      )}
      <ChallengesOfIT strapiData={challengesOfIT} />
      <IZContribute strapiData={izContribute} />
      <IZHealth strapiData={itHeallth} />
      <FourStep strapiData={fourStep} />
      <EngagementModal strapiData={engagementModal} />
      <SuccessStories strapiData={successStories} />
      <PartnerSuccess strapiData={partnerSuccess} />
      <Faqs strapiData={faqs} pageName="Containerization" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query itInfrastructureConsultingServices {
    strapiPage(slug: { eq: "it-infrastructure-consulting-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          jsonData {
            InfrastructureServices {
              services
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default ITInfrastructureConsultingServices
