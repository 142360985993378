import { Link } from "gatsby"
import React, { useContext } from "react"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import minus from "../../images/Healthcare/minus.svg"
import plus from "../../images/Healthcare/plus.svg"
import * as styles from "./Deliverables.module.scss"

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle as={Card.Header} onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
        <div type="button">
          {isCurrentEventKey ? (
            <img src={minus} className={styles.quesColorSelected} alt="icon" />
          ) : (
            <img src={plus} className={styles.quesColor} alt="icon" />
          )}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Deliverables = ({ strapiData }) => {
  // console.log("🚀 ~ Deliverables ~ strapiData:", strapiData)
  return (
    <div className={styles.deliverables}>
      <div className={styles.nextGenBehold}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <h2
                className={styles.heading}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <p
                className={styles.subDescription}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
            <Col lg={6}>
              <Accordion>
                <div className="fintechAccordian">
                  {strapiData?.cards?.map((item, i) => (
                    <Card key={i} border="light">
                      <ContextAwareToggle eventKey={`ek-${i}`}>
                        <div className={styles.accImg}>
                          <h3 className="mb-0 py-2" type="button">
                            {item?.title}
                          </h3>
                        </div>
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={`ek-${i}`}>
                        <Card.Body className={styles.textColor}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.subTitle,
                            }}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </div>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Deliverables
