// extracted by mini-css-extract-plugin
export var accImg = "Deliverables-module--accImg--cd47e";
export var approachImg = "Deliverables-module--approachImg--8d480";
export var btn_white1_border = "Deliverables-module--btn_white1_border--5411c";
export var deliverables = "Deliverables-module--deliverables--1eaae";
export var description = "Deliverables-module--description--a6cfe";
export var finttechRight = "Deliverables-module--finttechRight--7636b";
export var head = "Deliverables-module--head--b62b4";
export var heading = "Deliverables-module--heading--cf414";
export var keyBtn = "Deliverables-module--keyBtn--3b948";
export var nextGenBehold = "Deliverables-module--nextGenBehold--8a0e9";
export var quesColor = "Deliverables-module--quesColor--3cb31";
export var quesColorSelected = "Deliverables-module--quesColorSelected--a2302";
export var someColor = "Deliverables-module--someColor--1513d";
export var subDescription = "Deliverables-module--subDescription--f118e";
export var textColor = "Deliverables-module--textColor--3829e";