// extracted by mini-css-extract-plugin
export var arrowAnimation = "Product-delivery-module--arrowAnimation--4594c";
export var black = "Product-delivery-module--black--e509d";
export var card = "Product-delivery-module--card--d85af";
export var card1 = "Product-delivery-module--card1--9e0fc";
export var card2 = "Product-delivery-module--card2--a6a26";
export var card3 = "Product-delivery-module--card3--2b95b";
export var card4 = "Product-delivery-module--card4--f04ad";
export var card5 = "Product-delivery-module--card5--9b6f2";
export var cardContainer = "Product-delivery-module--cardContainer--9701f";
export var heading = "Product-delivery-module--heading--44984";
export var icon = "Product-delivery-module--icon--bca1b";
export var postDelivery = "Product-delivery-module--postDelivery--0a731";
export var progress = "Product-delivery-module--progress--5c98b";
export var subDescription = "Product-delivery-module--subDescription--d98f8";
export var white = "Product-delivery-module--white--68deb";