// extracted by mini-css-extract-plugin
export var arrowAnimation = "LaunchProduct-module--arrowAnimation--3cfff";
export var black = "LaunchProduct-module--black--652e1";
export var blue = "LaunchProduct-module--blue--fbfc7";
export var card = "LaunchProduct-module--card--4986d";
export var card1 = "LaunchProduct-module--card1--52837";
export var card2 = "LaunchProduct-module--card2--a833b";
export var card3 = "LaunchProduct-module--card3--3d779";
export var colGap = "LaunchProduct-module--colGap--c2172";
export var description = "LaunchProduct-module--description--86e52";
export var heading = "LaunchProduct-module--heading--02411";
export var newBtn = "LaunchProduct-module--newBtn--43d1b";
export var progress = "LaunchProduct-module--progress--43b4c";
export var progressSec = "LaunchProduct-module--progressSec--ab766";
export var subDescription = "LaunchProduct-module--subDescription--69b85";
export var subHeading = "LaunchProduct-module--subHeading--ca7b0";
export var white = "LaunchProduct-module--white--41870";