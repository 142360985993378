// extracted by mini-css-extract-plugin
export var SliderWidth = "Capabilities-module--SliderWidth--6134f";
export var capabilities = "Capabilities-module--capabilities--99945";
export var card = "Capabilities-module--card--fa0bd";
export var cardWrapper = "Capabilities-module--cardWrapper--b3219";
export var description = "Capabilities-module--description--78dae";
export var fineBg = "Capabilities-module--fineBg--259f9";
export var heading = "Capabilities-module--heading--74d2e";
export var headingPremium = "Capabilities-module--headingPremium--5dcb4";
export var iconContainer = "Capabilities-module--iconContainer--789e3";
export var iconContainerLeft = "Capabilities-module--iconContainerLeft--cce3f";
export var portfolioArrowIcon = "Capabilities-module--portfolioArrowIcon--e2c67";
export var portfolioArrowIconCover = "Capabilities-module--portfolioArrowIconCover--f57fb";
export var portfolioArrowRightIconCover = "Capabilities-module--portfolioArrowRightIconCover--3d1fe";
export var premiumImg = "Capabilities-module--premiumImg--5001e";
export var teamButton = "Capabilities-module--teamButton--283c6";