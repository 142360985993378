import React, { useEffect, useState } from "react"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./DedicatedSoftware.module.scss"
import "../../components/fixed-price/FixedHorizon.scss"
import { Link } from "gatsby"

const DedicatedSoftware = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(strapiData?.cards[0]?.title)
  const [pathname, setPathname] = useState("")

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <>
      <div
        className="p-85 fixHorizontalTabing"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />

          <Row>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={strapiData?.cards[0]?.title}
            >
              <Col lg={6}>
                <div>
                  <Nav variant="pills" className={`flex-column navbarBlock`}>
                    {strapiData?.cards?.map((e, i) => (
                      <Nav.Item key={i}>
                        <div
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                          dangerouslySetInnerHTML={{
                            __html: e?.title,
                          }}
                        />
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col lg={6}>
                <Tab.Content className="horizonBlock">
                  {strapiData?.cards?.map(
                    (e, i) =>
                      activeTab === e.title && (
                        <div key={i} className={`tab-pane active`}>
                          <div className={styles.boxContent}>
                            <div className={styles.fineBg}>
                              <img
                                decoding="async"
                                loading="lazy"
                                src={e?.image1[0]?.localFile?.publicURL}
                                alt="dedicated-software"
                              />
                            </div>
                            <h3
                              dangerouslySetInnerHTML={{
                                __html: e?.title2,
                              }}
                            />
                            <p
                              className={styles.desc}
                              dangerouslySetInnerHTML={{
                                __html: e?.subTitle,
                              }}
                            />
                            <div className={styles.btns}>
                              {e?.buttons[0] && (
                                <Link to={e?.buttons[0]?.url}>
                                  <div
                                    className={`${styles.baseBtn} ${styles.darkBtn} blueButton`}
                                  >
                                    <p>{e?.buttons[0]?.title}</p>
                                  </div>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </>
  )
}
export default DedicatedSoftware
