import React from "react"
import * as styles from "./TabsMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
// import "./Clients.scss"
import Slider from "react-slick"

const TabsMobile = ({ strapiData, projectEngRTest }) => {
  const data = [
    {
      name: "Web App Development",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/fi_6492030_1_b363ed7cf8.svg",
      dec: "Get a scalable, secure web app designed to meet your unique business needs and drive performance.",
      skills: [
        "Fast, secure performance",
        "Scalable for future growth",
        "Seamless cross-device experience",
        "Customizable features",
      ],
    },
    {
      name: "Mobile App Development",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007751_1_519a5e8c49.svg",
      dec: "Experience smooth and intuitive mobile apps for iOS and Android with seamless functionality across devices.",
      skills: [
        "iOS & Android apps",
        "Scalable for growth",
        "Cross-platform compatibility",
        "Engaging user interface",
      ],
    },
    {
      name: "API Development",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007752_1_441c1a8bed.svg",
      dec: "Enhance your software’s capabilities with flexible, well-documented APIs that ensure easy integration.",
      skills: [
        "Easy system integration",
        "Scalable & flexible APIs",
        "Secure and reliable",
        "Customizable for needs",
      ],
    },
    {
      name: "Enterprise Data Solutions",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007749_1_5d9cfd744f.svg",
      dec: "Streamline and optimize data across systems to drive smarter decisions and enhance performance.",
      skills: [
        "Integrated data systems",
        "Real-time data insights",
        "Improved operational efficiency",
        "Smarter decision-making",
      ],
    },
    {
      name: "Cloud Services",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007756_1_cabe927ecd.svg",
      dec: "Scale your infrastructure with secure, reliable cloud solutions tailored to your business needs.",
      skills: [
        "Flexible cloud deployment",
        "High-performance services",
        "Easy migration & management",
        "Enhanced security & compliance",
      ],
    },
    {
      name: "System Integrations",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007753_1_fd1caa0e14.svg",
      dec: "Connect your systems seamlessly to improve workflows, automation, and overall efficiency.",
      skills: [
        "Seamless system connections",
        "Scalable integration design",
        "Improved workflow automation",
        "Cost-saving efficiency",
      ],
    },
    {
      name: "Real-Time Application Development",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007758_1_02cb1e6ad9.svg",
      dec: "Get real-time data processing and immediate responses to enhance user experience and operational efficiency.",
      skills: [
        "Instant data processing",
        "Real-time insights",
        "Immediate response actions",
        "Enhanced user experience",
      ],
    },
    {
      name: "Launch Ready",
      img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007823_bc407e8c4d.svg",
      dec: "Prepare for a seamless software launch with full infrastructure, hosting, and ongoing support.",
      skills: [
        "Hassle-free go-live",
        "Full hosting & licensing",
        "Pre-launch testing",
        "Post-launch support",
      ],
    },
  ]

  const settings = {
    infinite: true,
    autoplay: false,
    slidesToShow: 2,
    dotsClass: "new-dot-python",
    centerPadding: "0px",
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: false,
          // dotsClass: "new-dot-python",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: false,
          // dotsClass: "new-dot-python",
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className="align-items-center clientSlider">
            <Slider {...settings} className={styles.SliderWidth}>
              {strapiData?.cards?.map((el, i) => (
                <Col key={i} className={styles.slideWrapper}>
                  <div
                    className={`${styles.cards} ${
                      i % 2 === 0 ? styles.greyCard : styles.redCard
                    } ${styles.cardHover}`}
                  >
                    <img
                      decoding="async"
                      loading="lazy"
                      src={el?.image1[0]?.localFile?.publicURL}
                      alt="img"
                    />
                    {/* <img src={el?.image1[0]?.localFile?.publicUR} className={styles.image2} alt="img" /> */}
                    <div className={styles.cardContent}>
                      <h3>{el?.title}</h3>
                      <p className={styles.subTitle}>{el?.subTitle}</p>
                      <div className={styles.points}>
                        {el.jsonData.Containerization.services.map(
                          (skill, eIdx) => (
                            <div key={eIdx} className={styles.arrowPoint}>
                              <img
                                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707478916_83a96a0f17.svg"
                                decoding="async"
                                loading="lazy"
                                alt=""
                              />
                              <p className={`${styles.skill}`}>{skill}</p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default TabsMobile
